import { FC } from "react"

const FieldUnderscore: FC<FieldUnderscoreProps> = ({ header }) => {
    return (
        <div className="d-flex align-items-end">
            <span className="header-2">{header}:</span>
            <div className="underscore-fill" />
        </div>
    )
}

interface FieldUnderscoreProps {
    header: string,
}

export default FieldUnderscore