import { FC } from "react"
import { useTranslation } from "react-i18next"

import { DeliveryParty } from "@/common/models/deliveryParty"
import Field from "./Field"
import FieldUnderscore from "./FieldUnderscore"

const NoteDeliveryParty: FC<NoteDeliveryPartyProps> = ({ role, data }) => {
    const { t } = useTranslation()

    return (
        <div>
            <div className="header-1">
                <span>{t(`deliveryParty.${role}`)}</span>
            </div>
            <Field
                header={t('pages.orderNotePrint.companyName')}
                value={data.company?.name}
            />
            <Field
                header={t('pages.orderNotePrint.fullName')}
                value={data.contactName}
            />
            <Field
                header={t('pages.orderNotePrint.city')}
                value={data.address?.city?.name}
            />
            <Field
                header={t('pages.orderNotePrint.address')}
                value={data.address?.addressString}
            />
            <Field
                header={t('pages.orderNotePrint.phone')}
                value={data.phone}
            />
            <FieldUnderscore
                header={role === 'receiver' ? 
                    t('pages.orderNotePrint.pickup') 
                    : t('pages.orderNotePrint.deliver')}
            />
        </div>
    )
}

interface NoteDeliveryPartyProps {
    role: 'sender' | 'receiver',
    data: DeliveryParty,
}

export default NoteDeliveryParty