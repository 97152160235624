export const ruOrdersPage = {
  "tabs": {
    "active": "Активные",
    "archive": "Завершенные"
  },
  "buttons": {
    "exportOrders": "Выгрузить в Excel",
    "importOrders": "Импорт заказов"
  },
  "table": {
    "created": "Создан",
    "orderNumber": "Номер накладной",
    "parcelType": "Тип",
    "deliveryType": "Доставка",
    "deliveryDate": "Дата доставки",
    "sender": "Отправитель",
    "senderInfo": "Контакты отправителя",
    "receiver": "Получатель",
    "receiverInfo": "Контакты получателя",
    "courier": "Курьер",
    "route": "Маршрут",
    "cell": "Ячейка",
    "itemPartsCount": "Количество квитанций",
    "estimatedDeliveryDate": "Ожидаемая дата доставки",
    "details": "Детали заказа",
    "status": "Статус"
  },
  "details": {
    "dropdowns": {
      "notSelected": "Не выбрано",
      "deliveryType": {
        "label": "Выберите тип доставки"
      },
      "parcelType": {
        "label": "Выберите тип посылки"
      },
      "changeStatus": {
        "label": "Изменить статус"
      }
    },
    "detailsHeader": "Детали заказа",
    "createDate": "ДАТА СОЗДАНИЯ",
    "sender": "ОТПРАВИТЕЛЬ",
    "receiver": "ПОЛУЧАТЕЛЬ",
    "senderCity": "Город отправителя",
    "receiverCity": "Город получателя",
    "street": "Улица",
    "building": "Дом",
    "apartment": "Офис/кв",
    "contactName": "Контактное лицо",
    "phone": "Номер телефона",
    "deliveryType": "Тип доставки",
    "parcelType": "Тип посылки",
    "route": "Маршрут",
    "cell": "Ячейка",
    "courier": "Курьер",
    "deliveryDate": "Дата доставки",
    "cost": "Стоимость",
    "comments": "Комментарии",
    "orderId": "Заказ",
    "companyName": "Наименование компании",
    "changeHistory": "История изменений",
    "attachmentButton": "Вложения",
    "edit": "Редактировать",
    "cancelEdit": "Отменить редактирование",
    "delete": "Удалить",
    "saveConfirm": {
      'header': 'Вы уверены, что хотите изменить этот заказ?'
    },
    "deleteConfirm": {
      "header": "Вы уверены, что хотите удалить этот заказ?",
      "body": "Это действие необратимо."
    },
    "save": "Сохранить",
    "cancel": "Отмена",
    "printSticker": "Распечатать наклейку",
    "printNote": "Распечатать накладную",
    "confirmOrder": "Подтвердить заказ"
  },
  "selectBar": {
    "selected": "Выбрано",
    "abortAll": "Сбросить все",
    "alerts": {
      "fail": {
        "markAllOrdersToPrintStickers": "Промаркируйте все выбранные доставки для печати наклеек",
        "selectOrdersWithAssignedCouriers": "Выберите заказы с назначенным курьером",
        "selectedOrdersHaveIncorrectStatus": "Выбранные доставки имеют некорректный статус",
        "selectedOrdersShouldbeConfirmed": "Выбранные доставки должны быть подтверждены",
        "selectedOrdersHaveDifferentStatuses": "Выбранные доставки имеют разные статусы. Действие невозможно",
        "assignCourier": "Сначала назначьте курьера на доставки",
        "serverError": "Ошибка сервера"
      },
      "success": {
        "courierAssigned": "Курьеры успешно назначены",
        "cellAssigned": "Ячейки успешно назначены",
        "statusChanged": "Статусы успешно изменены"
      }
    },
    "buttons": {
      "changeStatus": "Изменить статус",
      "print": "Печать",
      "printStickers": "Печать наклеек",
      "printCouriers": "Печать курьеров",
      "printNotes": "Печать накладных"
    }
  },
  "stickers": {
    "sender": "От кого",
    "receiver": "Кому",
    "id": "Номер заказа",
    "orderId": "Номер доставки",
    "createDate": "Дата создания",
    "orderNumber": "Номер накладной",
    "route": "Маршрут",
    "unsupportedRoute": "Вне зоны"
  }
};