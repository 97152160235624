import React, { forwardRef, useEffect, useRef } from 'react'
import { Table } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'

import './index.css'
import { OrderItem } from '@/common/models/order'
import { buildDeliveryPartyString } from '@/common/utils/utils'
import Heading from '@/components/typography/Heading'

const OrderListPrintPage = () => {
    const componentRef = useRef()
    const location = useLocation()
    const orderItems: OrderItem[] = JSON.parse(location?.state?.orderItems ?? "[]")

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
          @media print {
            .body-table {
              page-break-after: always;
            }
          }
        `,
    })

    const groupedOrderItems = orderItems.reduce((acc, order) => {
        const courierKey = `${order.courier.firstName} ${order.courier.lastName}`
      
        if (!acc[courierKey]) {
          acc[courierKey] = []
        }
      
        acc[courierKey].push(order)
      
        return acc
    }, {} as Record<string, OrderItem[]>)

    useEffect(() => {
        handlePrint()
    }, [handlePrint])

    if (!orderItems || orderItems.length < 1) {
        return <div>No order details available.</div>
    }

    return (
        <div>
            <div className="p-3" ref={componentRef}>
                {Object.keys(groupedOrderItems).map((courierName => (
                    <div key={courierName}>
                        <OrderListPrintable
                            courierName={courierName}
                            orderItems={groupedOrderItems[courierName]}
                        />
                    </div>
                )))}
                <button className={"d-none"} id="stickers-print" onClick={handlePrint}>Печать реестра курьеров</button>
            </div>
        </div>
    )
}

const OrderListPrintable = forwardRef<HTMLDivElement, OrderListPrintableProps>(({ courierName, orderItems }, ref) => {
    return (
        <div className="body-table" ref={ref}>
            <div>
                <Heading size="4">{new Date().toLocaleDateString()}</Heading>
                <div className="d-flex justify-content-between mt-5">
                    <span>Принято: {orderItems.length}, {courierName}</span>
                    <span>Подпись курьера: ____________</span>
                    <span>Маршрут: ____________</span>
                </div>
            </div>
            <Table className="mt-4 print-table" bordered striped size="sm">
                <thead>
                    <tr>
                        <th>№</th>
                        <th>Получатель</th>
                        <th>ФИО</th>
                        <th>Должность</th>
                        <th>Подпись</th>
                        <th>Выдана</th>
                    </tr>
                </thead>
                <tbody>
                    {orderItems.map((o, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{buildDeliveryPartyString(o.deliveryReceiver)}</td>
                            <td className="w-25"></td>
                            <td className="w-25"></td>
                            <td style={{ width: '5%' }}></td>
                            <td style={{ width: '10%' }}></td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
})

interface OrderListPrintableProps {
    orderItems: OrderItem[],
    courierName: string,
    ref: HTMLDivElement
}

export default OrderListPrintPage