export const ruOrderCreate = {
  useData: "Использованы ваши данные",
  form: {
    companyName: 'Название компании',
    bin: 'БИН компании',
    city: 'Город',
    address: 'Адрес',
    building: 'Дом',
    apartment: 'Офис/кв.',
    phone: 'Номер телефона',
    contactName: 'Контактное лицо',
    addressComments: 'Дополнительные комментарии по адресу',
  },
  addressSelect: {
    noAddress: "если он не был найден",
    chooseAddress: "Выберите вариант из списка или добавьте адрес",
    addAddress: "Добавить адрес",
    typeAddress: "Введите адрес"
  },
  companySelect: {
    choose: "Выберите компанию",
    type: "Введите компанию",
    minText: "Введите как минимум 3 символа",
    noData: 'Компания {{searchQuery}} не найдена',
  },
  confirmForm: {
    deliveryInfo: "Данные доставки",
    back: "Назад",
    forward: "Далее",
    saveChanges: "Сохранить изменения",
    create: "Создать заказ"
  }
}