import React, { FC } from 'react'
import { Badge } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { Formats } from '@/common/constants/dateFormat'
import { Order } from '@/common/models/order'
import { convertToTimeZone } from '@/common/utils/utils'
import { Boxes, BoxSeam } from 'react-bootstrap-icons'

const OrderCard: FC<OrderCardProps> = ({ data, onClick }) => {
    const { t } = useTranslation()

    return (
        <div className="position-relative border border-rounded-2 d-flex flex-column bg-white my-3 p-3" onClick={onClick}>
            <div className="position-absolute" style={{ top: '-12px' }}>
                <Badge bg="light" className="border" pill>
                    <span className="ms-auto text-dark heading-badge">{convertToTimeZone(data.createDate, false, Formats.DATE_DMY_TIME)}</span>
                </Badge>
            </div>
            <div className="d-flex heading-font">
                <span>{t(`orderStatus.${data.status}`)}</span>
            </div>
            <div>
                <div className="d-flex">
                    <span>№{data.id}</span>
                    <span>{', ' + data.deliverySender?.company?.name}</span>
                </div>
                <div>
                    {data.orderItems?.length > 1 ? (
                        <Badge bg="primary" className="border heading-badge" pill>
                            <Boxes /> {data.orderItems.length} {t('telegram.pages.orderAction.counter.orderItems')}
                        </Badge>
                    ) : (
                        <Badge bg="light" className="border text-dark heading-badge" pill>
                            <BoxSeam /> {data.orderItems.length} {t('telegram.pages.orderAction.counter.orderItem')}
                        </Badge>
                    )}
                </div>
            </div>
        </div>
    )
}

interface OrderCardProps {
    data: Order,
    onClick: () => void,
}

export default OrderCard