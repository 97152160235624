import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import {formatDate, subDays, subMonths } from 'date-fns'

import { dateOptions, reportTypeOptions } from './options'
import { DateRange } from '@/common/models/util'
import {formatPeriod, validateDateFormat} from "@/common/utils/utils";
import DateRangeCalendar from '@/components/controls/calendar/DateRangeCalendar'
import { Formats } from '@/common/constants/dateFormat'

const ReportSelectorBar = ({ onReportTypeChange, onDateChange }: ReportSelectorBarProps) => {
    const { t } = useTranslation()

    const [reportType, setReportType] = useState('cell-load')
    const [showCustomPeriod, setShowCustomPeriod] = useState<boolean>(false)
    const [customPeriod, setCustomPeriod] = useState({
        startDate: "",
        endDate: "",
    })

    const handlePeriodChange = (period: string) => {
        if (period === 'custom') {
            return setShowCustomPeriod(true)
        } else {
            setShowCustomPeriod(false)
        }

        const today: Date = new Date()
        const todayStr: string = formatDate(new Date(), Formats.DATE_YMD)

        switch (period) {
            case '*':
                onDateChange(null)
                setCustomPeriod({
                    startDate: "",
                    endDate: "",
                })
                break
            case 'yesterday':
                const dayAgo: string = formatDate(subDays(today, 1), Formats.DATE_YMD)
                setCustomPeriod({ startDate: dayAgo, endDate: todayStr })
                onDateChange({ startDate: dayAgo, endDate: todayStr })
                break
            case '3day':
                const threeDaysAgo: string = formatDate(subDays(today, 3), Formats.DATE_YMD)
                setCustomPeriod({ startDate: threeDaysAgo, endDate: todayStr })
                onDateChange({ startDate: threeDaysAgo, endDate: todayStr })
                break
            case 'weekly':
                const weekAgo: string = formatDate(subDays(today, 7), Formats.DATE_YMD)
                setCustomPeriod({ startDate: weekAgo, endDate: todayStr })
                onDateChange({ startDate: weekAgo, endDate: todayStr })
                break
            case 'monthly':
                const monthAgo: string = formatDate(subMonths(today, 1), Formats.DATE_YMD)
                setCustomPeriod({ startDate: monthAgo, endDate: todayStr })
                onDateChange({ startDate: monthAgo, endDate: todayStr })
                break
        }
    }

    const handleReportTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setReportType(e.target.value)
        onReportTypeChange(e.target.value)
    }

    const handleCustomPeriodSubmit = (range) => {
        setShowCustomPeriod(false)
        setCustomPeriod({ startDate: validateDateFormat(range.fromCreateDate, Formats.DATE_YMD), endDate: validateDateFormat(range.toCreateDate, Formats.DATE_YMD) })
        onDateChange({ startDate: validateDateFormat(range.fromCreateDate, Formats.DATE_YMD), endDate: validateDateFormat(range.toCreateDate, Formats.DATE_YMD) })
    }

    const getDateOptions = () => {
        return dateOptions
            .filter(d => d.availableFor.includes(reportType))
            .map((o, index) => (
                o.value === "custom" && customPeriod?.startDate && customPeriod?.endDate ? (
                    <option 
                        key={index}
                        value={o.value}
                        className="d-none"
                    >
                        {formatPeriod(
                            validateDateFormat(customPeriod?.startDate, Formats.DATE_DMY),
                            validateDateFormat(customPeriod?.endDate, Formats.DATE_DMY)
                        )}
                    </option>
                ) : (
                    <option
                        key={index}
                        value={o.value}
                    >
                        {t(`reportPage.dateRanges.${o.text}`)}
                    </option>
                )
            ))
    }

    return (
        <div className="d-inline-flex">
            <Form.Select
                className="border-rounded-1 w-100"
                onChange={handleReportTypeChange}
                style={{ maxWidth: '300px' }}
            >
                {reportTypeOptions.map((o, index) => (
                    <option key={index} value={o.value}>{t(`reportPage.options.${o.value}`)}</option>
                ))}
            </Form.Select>
            <div>
                <Form.Select
                    className="border-rounded-1 w-100 mx-2"
                    onChange={(e) => handlePeriodChange(e.target.value)}
                    style={{ maxWidth: '300px' }}
                >
                    {getDateOptions()}
                </Form.Select>
                {showCustomPeriod && (
                    <div className="bg-white border border-rounded-2 shadow position-absolute mt-2 p-2">
                        <DateRangeCalendar
                            onSubmit={handleCustomPeriodSubmit}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

interface ReportSelectorBarProps {
    onDateChange: (dateRange: DateRange) => void,
    onReportTypeChange: (type: string) => void,
}

export default ReportSelectorBar