import React, { FC, useState } from 'react';
import AsyncSelect  from 'react-select/async'
import {components, OptionProps} from "react-select";
import { useTranslation } from 'react-i18next';

import {CompanyService} from "@/common/api/CompanyService";
import useDebounce from "@/common/hooks/useDebounce";
import { AddressData } from "@/common/models/address"
import {getPointIfPresent} from "@/common/utils/utils";
import { SelectOption } from '@/common/models/util'

export const customSelectStyles = {
    input: (provided) => ({
        ...provided,
        width: "100%",
        padding: "0.2rem 0.5rem",
        fontSize: "1rem",
    }),
    singleValue: (provided) => ({
        ...provided,
        padding: "0.2rem 0.5rem",
        color: '#212529',
    }),
    control: (provided, meta) => {
        let color = '#c9c9c9'
        const isTouched = meta.selectProps.isTouched
        if(isTouched && !meta?.getValue()[0]?.value?.street){
            color = 'red'
        }
        return {
            ...provided,
            width: '100%',
            borderColor: color,
            borderWidth: '2px',
            borderRadius: '0.5rem',
        };
    },
}

const AddressAsyncSelect: FC<AddressAsyncSelectProps> = ({ prevAddress, validate = () => {}, onSelect = () => {}, onInputChange = () => {} }) => {
    const {t} = useTranslation()
    const [isTouched, setIsTouched] = useState(false);
    const [searchQuery, setSearchQuery] = useState<string>(
      `${prevAddress?.street || ""} ${prevAddress?.building || ""}`
    );
    const [selectedOption, setSelectedOption] = useState<SelectOption>({
        value: {
            building: prevAddress?.building || "",
            street: prevAddress?.street || "",
            cityId: prevAddress?.city?.id,
            cityName: prevAddress?.city?.name,
            point: getPointIfPresent(prevAddress?.point?.lat, prevAddress?.point?.lon)
        },
        label: `${prevAddress?.street || ""} ${prevAddress?.building || ""}`,
    })
    const buildSelectOption = (item) => ({
        value: item,
        label: `${item?.street || ""}, ${item?.building || ""}${item?.apartment ? `, ${item?.apartment}` : ""}`,
    });

    const fetchSuggestions = async (query: string, callback) => {
        if (query?.length < 2) return [];
        try {
            const response = await CompanyService.suggestAddresses({
                cityId: prevAddress.city.id,
                street: query.trim()
            });
            const fetchedOptions = response?.items || [];
            callback([
              ...fetchedOptions.map(buildSelectOption),
                {
                    value: 'hint',
                    label: <>{t('orderCreate.addressSelect.chooseAddress')}, {t('orderCreate.addressSelect.noAddress')}</>,
                    isError: true,
                    isHint: true,
                    isDisabled: true,
                },
                {
                    value: {
                        building: " ",
                        street: query,
                        cityId: prevAddress?.city?.id,
                        cityName: prevAddress?.city?.name,
                        point: null,
                    },
                    label: `+ ${t('orderCreate.addressSelect.addAddress')}`,
                    isHint: true,
                    isError: false,
                    isDisabled: false,
                }
                ]);
        } catch (error) {
            console.error(error);
        }
    };

    const debouncedFetchSuggestions = useDebounce(fetchSuggestions, 1000)

    const handleSelectInputChange = (inputValue: string, {action}) => {
        setSearchQuery(inputValue);
        if(action !== 'input-blur' && action !== 'menu-close'){
            setSelectedOption({
                label: inputValue,
                value: {
                    building: undefined,
                    street: undefined,
                    cityId: prevAddress?.city?.id,
                    cityName: prevAddress?.city?.name,
                    point: null
                }
            })
            onInputChange(undefined, undefined)
        }
    };
    const handleOptionSelect = (option) => {
        if(option?.isHint) {
            const newOption = {
                ...option,
                label: `${option?.value?.street}`
            }
            onSelect(option?.value);
            setSelectedOption(newOption)
            onInputChange(option?.value?.street, " ")

        } else {
            onSelect(option?.value);
            setSelectedOption(option)
            onInputChange(option?.value?.street, option?.value?.building)
        }
        validate()
    };
    const handleFocusSelect = () => {
        setSearchQuery(selectedOption?.label)
        fetchSuggestions(searchQuery, () => {})
    }
    const handleBlurSelect = () => {
        setIsTouched(true);
    };

    return (
        <div>
            <AsyncSelect
                isTouched={isTouched}
                value={selectedOption}
                styles={customSelectStyles}
                components={{Option: CustomOption}}
                loadOptions={debouncedFetchSuggestions}
                onChange={handleOptionSelect}
                inputValue={searchQuery}
                onInputChange={handleSelectInputChange}
                onBlur={handleBlurSelect}
                onFocus={handleFocusSelect}
                blurInputOnSelect={false}
                loadingMessage={() => <span className={"text-primary"}>{t('api.messages.loading')}...</span>}
                noOptionsMessage={() => t('orderCreate.addressSelect.typeAddress')}
            />
            {isTouched && !selectedOption?.value?.street &&  (
              <span
                className={'text-danger'}>{t('orderCreate.addressSelect.chooseAddress')}</span>
            )}
        </div>

    );
}

interface AddressAsyncSelectProps {
    prevAddress: AddressData,
    validate?: () => void,
    onSelect?: (address: AddressData) => void,
    onInputChange?: (street: string, building: string) => void,
}

const CustomOption: React.FC<CustomOptionProps> = (props) => {
    return (
        <components.Option {...props}>
            {props.data.isHint ? (
                props.data.isError ? (
                    <div className={"text-muted"}>{props.data.label}</div>
                    ) : (
                    <div className={"text-primary"}>{props.data.label}</div>
                )
            ) : (
                props.children
            )}
        </components.Option>
    );
};

interface CustomOptionProps extends OptionProps<any, any> {

    data: {
        label: string;
        value: any;
        [key: string]: any;
    };
}

export default AddressAsyncSelect