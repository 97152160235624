import React from 'react'
import { Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { ReportDeliveredOrderItemCount } from '@/common/models/report'

const ReportDeliveredTable = ({ reportData }: ReportDeliveredTableProps) => {
    const { t } = useTranslation()

    return (
        <div>
            <Table bordered striped size="sm">
                <thead>
                    <tr>
                        <th>{t('reportPage.labels.delivered.date')}</th>
                        <th>{t('reportPage.labels.delivered.totalOrderItems')}</th>
                        <th>{t('reportPage.labels.delivered.totalDelivered')}</th>
                        <th>{t('reportPage.labels.delivered.totalNotDelivered')}</th>
                    </tr>
                </thead>
                <tbody>
                    {reportData?.data?.map(row => (
                        <tr>
                            <td>{row.date}</td>
                            <td>{row.totalCount}</td>
                            <td>{row.deliveredParcelCount}</td>
                            <td>{row.nonDeliveredParcelCount}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

interface ReportDeliveredTableProps {
    reportData: ReportDeliveredOrderItemCount,
}

export default ReportDeliveredTable