import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { ruTranslation } from '@/common/constants/locales/ru/translation';

const resources = {
  // kk: ,
  ru: ruTranslation,
}


declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}
i18n
    // .use(I18NextHttpBackend)
    .use(initReactI18next)
    .init({
        fallbackLng: 'ru',
        lng: 'ru',
        debug: true,
        resources,
    });