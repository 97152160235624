import React from 'react'
import { Spinner } from 'react-bootstrap'
import { DatabaseExclamation, CupHotFill } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'

import ReportCellLoadTable from './tables/ReportCellLoadTable'
import ReportDeliveredTable from './tables/ReportDeliveredTable'
import ReportCourierDeliveryDynamicsTable from './tables/ReportCourierDeliveryDynamicsTable'

const ReportView = ({ data, isLoading, error, reportType }: ReportViewProps) => {
    const { t } = useTranslation()

    if (isLoading) {
        return (
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                <Spinner />
                <span className="heading-2 my-3">{t('reportPage.labels.loadingReports')}</span>
            </div>
        )
    }

    if (error) {
        return (
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                <div className="d-flex flex-column align-items-center">
                    <span className="text-danger heading-2 mb-2">
                        <DatabaseExclamation />
                    </span>
                    <span className="heading-2 text-danger">{t('reportPage.labels.loadingError')}</span>
                </div>
                <span className="text-danger my-3">{error.message}</span>
            </div>
        )
    }

    if (!data || !reportType) {
        return (
            <div className="border-dotted border-rounded-2 d-flex justify-content-center align-items-center h-100">
                <div className="d-flex flex-column align-items-center">
                    <span className="text-muted heading-2 mb-2">
                        <CupHotFill />
                    </span>
                    <span className="heading-2 text-muted">{t('reportPage.labels.loadingNoData')}</span>
                </div>
            </div>
        )
    }

    const tables = {
        'cell-load': <ReportCellLoadTable reportData={data} />,
        'delivered': <ReportDeliveredTable reportData={data} />,
        'courier-delivery-dynamics': <ReportCourierDeliveryDynamicsTable reportData={data} />
    }

    const getReportTable = (reportType: string) => {
        return tables[reportType]
    }

    return (
        <div>{getReportTable(reportType)}</div>
    )
}

interface ReportViewProps {
    data: any,
    isLoading: boolean,
    error: any,
    reportType: string,
}

export default ReportView