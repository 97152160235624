export const ruReports = {
  labels: {
    "reports": "Отчёты",
      "reportParams": "Параметры отчёта",
      "loadingReports": "Получение отчёта",
      "loadingError": "Упс... что-то пошло не так",
      "loadingNoData": "Нет данных",
      "cell-load": {
      "date": "Дата",
        "totalInCells": "Кол-во в ячейках",
        "from1to4Days": "От 1 до 4 дня",
        "from5to9Days": "От 5 до 9 дней",
        "from10to19Days": "От 10 до 19 дней",
        "from20to29Days": "От 20 до 29 дней",
        "from30Days": "Свыше 30 дней"
    },
    "courier-delivery-dynamics": {
      "name": "Имя"
    },
    "delivered": {
      "date": "Дата",
        "totalOrderItems": "Общее количество писем",
        "totalDelivered": "Количество доставленных",
        "totalNotDelivered": "Количество не доставленных"
    }
  },
  buttons: {
    "sendBtn": "Отправить"
  },
  options: {
    "cell-load": "Отчёт по нагрузке",
      "delivered": "Отчет по количеству писем",
      "courier-delivery-dynamics": "Отчет по количеству писем на руках у курьеров"
  },
  dateRanges: {
    "notSelected": "Выберите период",
      "forYesterday": "За вчерашний день",
      "for3Day": "За 3 дня",
      "forWeek": "За неделю",
      "forMonth": "За месяц",
      "customRange": "Выбрать период"
  }
}