import React from 'react';
import {Pagination} from "react-bootstrap";
import {ChevronLeft, ChevronRight} from 'react-bootstrap-icons'

import './Pagination.scss'

const CustomPagination = ({controlPage: {page, previousPage, resetPage, nextPage, isLast, lastPage, left, totalPages, total}}: CustomPaginationProps) => {
    return (
        <Pagination>
            {(page !== 1) &&
                <Pagination.Prev
                    className={"px-1 fw-bold chevron"}
                    onClick={previousPage}
                ><ChevronLeft/></Pagination.Prev>
            }
            {(page !== 1 && totalPages > 5) && (
                <Pagination.First
                    className={"px-1 fw-bold"}
                    onClick={resetPage}
                >1</Pagination.First>
            )}
          {((page - 1) > 1 && totalPages > 5) && (
            <>
              <Pagination.Ellipsis
                className={"px-1 fw-bold"}
                disabled={true}
              >
                ...
              </Pagination.Ellipsis>
            </>
          )}
            <Pagination.Item
                className={"px-1 fw-bold"}
                linkStyle={{backgroundColor: "var(--primary)"}}
                active
            >
                {page}
            </Pagination.Item>
            {((page + 1) < totalPages && totalPages > 4) && (
                <Pagination.Item
                    className={"px-1 fw-bold"}
                    onClick={nextPage}
                >
                    {page + 1}
                </Pagination.Item>
            )}
            {(page < (totalPages - 2) && totalPages > 5) && (
                <>
                    <Pagination.Ellipsis
                        className={"px-1 fw-bold"}
                        disabled={true}
                    >
                        ...
                    </Pagination.Ellipsis>
                </>
            )}
            {(!isLast && totalPages > 5) && (
                <Pagination.Last
                    className={"px-1 fw-bold"}
                    onClick={() => lastPage(totalPages)}
                >{totalPages}</Pagination.Last>

            )}
            {(!isLast && page < totalPages) && (
                <Pagination.Next
                    className={"px-1 fw-bold"}
                    onClick={nextPage}
                ><ChevronRight /></Pagination.Next>
            )}
            <div className="d-flex align-items-center px-3">
                <span>{left} из {total}</span>
            </div>
        </Pagination>
    );
};

interface CustomPaginationProps {
  controlPage: {
    page: number,
    nextPage: () => void,
    previousPage: () => void,
    resetPage: () => void,
    lastPage: (page: number) => void,
    isLast: boolean,
    left: number,
    totalPages: number,
    total: number
  }
}

export default CustomPagination;