import { createContext } from "react"
import { Outlet } from "react-router-dom"
import resourceStore from "../storage/resourceStore"

export const ResourceContext = createContext({
    resourceStore,
})

const ResourceProvider = () => {
    return (
        <ResourceContext.Provider value={{resourceStore}}>
            <Outlet/>
        </ResourceContext.Provider>
    )
}

export default ResourceProvider