export const reportTypeOptions = [
    {
        value: 'cell-load',
    },
    {
        value: 'delivered',
    },
    {
    	value: 'courier-delivery-dynamics',
    }
]

export const dateOptions = [
    {
        value: '*',
        text: 'notSelected',
        availableFor: 'cell-load,delivered,courier-delivery-dynamics'
    },
    {
        value: 'yesterday',
        text: 'forYesterday',
        availableFor: 'cell-load,delivered,courier-delivery-dynamics'
    },
    {
        value: '3day',
        text: 'for3Day',
        availableFor: 'cell-load,delivered,courier-delivery-dynamics'
    },
    {
        value: 'weekly',
        text: 'forWeek',
        availableFor: 'cell-load,delivered,courier-delivery-dynamics'
    },
    {
        value: 'monthly',
        text: 'forMonth',
        availableFor: 'cell-load,delivered'
    },
    {
        value: 'custom',
        text: 'customRange',
        availableFor: 'cell-load,delivered'
    },
]
