import React from 'react'
import { useQuery } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { ResponseError } from 'superagent'

import OrderItemActionCard from './components/OrderItemActionCard'
import { OrderItem } from '@/common/models/order'
import OrderService from '@/telegram/api/OrderService'
import DefaultButton from '@/components/buttons/DefaultButton'

import './index.css'

const TelegramOrderItemActionPage = () => {
    const { t } = useTranslation()

    const navigate = useNavigate()
    const location = useLocation()
    const orderItemId = location.state?.orderItemId

    const { data, isLoading, error } = useQuery<OrderItem>('order-courier', () => OrderService.getById(orderItemId), { cacheTime: 0, staleTime: 0 })

    if (isLoading) {
        return (
            <div className="d-flex vh-100 align-items-center justify-content-center">
                <div className="d-flex flex-column align-items-center">
                    <Spinner/>
                    <span className="my-2">{t('telegram.pages.orderAction.progressLabels.loadingSingle')}...</span>
                </div>
            </div>
        )
    }

    if (error) {
        return (
            <div className="d-flex vh-100 align-items-center justify-content-center">
                <div className="d-flex flex-column align-items-center">
                    <span className="my-2 text-danger">
                        {(error as ResponseError)?.response?.body?.errorMessage 
                            || 'Service unavailable'}
                    </span>
                    <DefaultButton variant="light" onClick={() => navigate(-1)} bordered>
                        {t('telegram.pages.orderAction.buttons.goBackBtn')}
                    </DefaultButton>
                </div>
            </div>
        )
    }

    return (
        <div>
            {data && (
                <OrderItemActionCard data={data} />
            )}
        </div>
    )
}

export default TelegramOrderItemActionPage
