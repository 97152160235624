import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Input, Popconfirm, Table, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { formatDate } from 'date-fns';
import { ru } from 'date-fns/locale';

import { ImportTableOrder } from '@/common/models/order';
import CityAsyncSelect from '@/components/controls/select/CityAsyncSelect';
import { City } from '@/common/models/address';
import { SelectOption } from '@/common/models/util';
import useImport from '@/common/hooks/useImport';
import { changeAddress, getPluralApparments, getReadyToImportOrders, hasEmptyFields } from '@/common/utils/parsers';
import OrderImportConfirmModal from '@/pages/order-import/components/modals/OrderImportConfirmModal';
import { formatToTimeStamp } from '@/common/utils/utils';
import { Formats } from '@/common/constants/dateFormat';
import { CityApiPageResponse } from '@/common/models/api/v0/address.dto';
import OrderService from '@/common/api/OrderService';
import PhoneInputWithoutFormik from '@/components/controls/input/PhoneInputWithoutFormik';
import useAuth from '@/common/hooks/useAuth';
import { Roles } from '@/common/constants/roles';

export interface TableOrder {
  key: string,
  id: string,
  createDate: string,
  Sender: string,
  Receiver: string,
  total: number,
  phone: string,
  city: string,
  addressString: string,
  addressFromFile: string,
  addressComments: string,
  isFixed?: boolean,
}

const OrderImportTable: React.FC<OrderImportTableProps> = observer(({citiesData}) => {
  const hasMount = useRef(false)
  const {t} = useTranslation()
  const { hasAnyRole } = useAuth()
  const [form] = Form.useForm();
  const navigate = useNavigate()
  const {importForm, setImportForm} = useImport()
  const [tableOrders, setTableOrders] = useState<ImportTableOrder[]>([]);
  useEffect(() => {
    hasMount.current = true
  }, [])
  useEffect(() => {
    if(hasMount?.current){
      const brokenOrders = tableOrders?.filter(order => hasEmptyFields(order));
      if (importForm?.brokenOrders?.length !== brokenOrders?.length) {
        setImportForm({
          ...importForm,
          brokenOrders,
        });
      }
    }
  }, [importForm, setImportForm, tableOrders]);

  useEffect(() => {
    setTableOrders(prev => {
      return importForm?.orders?.map(orderItem => {
        const currTableOrder: TableOrder = prev?.find(tableOrder => tableOrder?.id === orderItem?.id);
        const initialOrder = importForm?.initialOrders?.find(initOrder => initOrder?.id === orderItem?.id);
        return {
          key: orderItem?.id,
          id: orderItem?.id,
          createDate: orderItem?.createDate,
          Sender: orderItem?.deliverySender?.company?.name,
          total: orderItem?.apartmentsTotal,
          Receiver: currTableOrder?.Receiver || orderItem?.deliveryReceiver?.company?.name,
          phone: currTableOrder?.phone || orderItem?.deliveryReceiver?.phone || '',
          city: citiesData?.cities.find(city => city?.id === orderItem?.deliveryReceiver?.address?.cityId)?.name || '',
          addressString: orderItem?.deliveryReceiver?.address?.addressString || '',
          isFixed: currTableOrder?.isFixed,
          addressFromFile: initialOrder?.addressFromFile || '',
          addressComments: importForm?.type === 'HH' ?
            (currTableOrder?.addressComments || initialOrder?.addressFromFile)
            :
            (currTableOrder?.addressComments || `${orderItem?.deliveryReceiver?.address?.addressComment}, ${getPluralApparments(Number(orderItem?.apartmentsTotal))}`) || '',
        };
      }).sort((a,b) => {
        if (hasEmptyFields(a) && hasEmptyFields(b)) return 0;
        if (hasEmptyFields(a)) return -1;
        if (hasEmptyFields(b)) return 1;

        if (a.isFixed && !b.isFixed) return -1;
        if (!a.isFixed && b.isFixed) return 1;

        return a.id - b.id
      })
    })
  }, [citiesData, importForm?.initialOrders, importForm?.orders, importForm?.type])

  const [editingKey, setEditingKey] = useState('');

  const isEditing = (record: ImportTableOrder) => record.key === editingKey;

  const deleteOrder = (record: Partial<ImportTableOrder> & { key: React.Key }) => {
    setTableOrders(prev => prev.filter(order => order?.id !== record?.id));
    setImportForm({
      ...importForm,
      orders: importForm?.orders?.filter(order => order?.id !== record?.id)
    })
  };

  const editOrder = (record: Partial<ImportTableOrder> & { key: React.Key }) => {
    form.setFieldsValue({ ...record });  // Set all fields when editing
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };
  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as ImportTableOrder;
      const newData = [...tableOrders];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        // Ensure addressComments is included when updating the row
        newData.splice(index, 1, {
          ...item,
          ...row,
          isFixed: true,
          addressComments: row.addressComments || item.addressComments // retain or update addressComments
        });

        setTableOrders(newData);

        await changeAddress(`${row?.city} ${row?.addressString}`, key, importForm?.orders, (orders) => {
          setImportForm({
            ...importForm,
            orders: orders
          });
        }, citiesData?.cities);

        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  // Apply custom class to rows based on field values
  const getRowClassName = (record: ImportTableOrder) => {
    if (hasEmptyFields(record)) return 'import-row-error' ;
    if (record.isFixed) return 'import-row-fixed';
    return  '';
  };

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      width: 30,
      sorter: {
        compare: (a, b) => a.id - b.id,
      },
    },
    {
      title: t('ordersImportPage.table.columns.createDate'),
      dataIndex: 'createDate',
      sorter: {
        compare: (a, b) => new Date(a.createDate).getTime() - new Date(b.createDate).getTime(),
        multiple: 3,
      },
      render: (_: any, record: ImportTableOrder) => {
        return <td width={120} className={'import-cell'}>
          {formatDate(formatToTimeStamp(record?.createDate), Formats.DATE_DMMMY_TIME, {locale: ru})}
        </td>;
      }
    },
    {
      title: t('ordersImportPage.table.columns.Sender'),
      dataIndex: 'Sender',
      render: (_: any, record: ImportTableOrder) => {
        return <td width={100} className={'import-cell'}>
          {record?.Sender}
        </td>;
      }
    },
    {
      title: t('ordersImportPage.table.columns.Receiver'),
      dataIndex: 'Receiver',
      editable: true,
      render: (_: any, record: ImportTableOrder) => {
        return <td width={350} className={'import-cell'}>
          {record?.Receiver}
        </td>;
      }
    },
    {
      title: t('ordersImportPage.table.columns.city'),
      dataIndex: 'city',
      editable: true,
      render: (_: any, record: ImportTableOrder) => {
        const currCity = citiesData?.cities?.find(city => city?.name === record?.city);
        return <td width={250} className={'import-cell'}>
          {currCity?.name}
        </td>;
      },
    },
    {
      title: t('ordersImportPage.table.columns.addressString'),
      dataIndex: 'addressString',
      editable: true,
      render: (_: any, record: ImportTableOrder) => {
        return <td className={'import-cell'}>
          {record?.addressString}
        </td>;
      }
    },
    {
      title: t('ordersImportPage.table.columns.addressFromFile'),
      dataIndex: 'addressFromFile',
      render: (_: any, record: ImportTableOrder) => {
        return <td width={400} className={'import-cell'}>
          {record?.addressFromFile}
        </td>;
      }
    },
    {
      title: t('ordersImportPage.table.columns.phone'),
      dataIndex: 'phone',
      editable: true,
      render: (_: any, record: ImportTableOrder) => {
        return <td width={200} className={'import-cell'}>
          {record?.phone}
        </td>;
      }
    },
    {
      title: t('ordersImportPage.table.columns.addressComments'),
      dataIndex: 'addressComments',
      editable: true,
      render: (_: any, record: ImportTableOrder) => {
        return <td width={150} className={'import-cell'}>
          {record?.addressComments} <br/>
        </td>;
      }
    },
    {
      title: t('ordersImportPage.table.columns.total'),
      dataIndex: 'total',
      render: (_: any, record: ImportTableOrder) => {
        return <td width={150} className={'import-cell'}>
          {record?.total}
        </td>;
      }
    },
    {
      title: t('ordersImportPage.table.columns.operation'),
      dataIndex: 'operation',
      render: (_: any, record: ImportTableOrder) => {
        const editable = isEditing(record);  // Determines if the record is in edit mode
        return editable ? (
          <span>
            <Popconfirm
              okButtonProps={{
                style: {
                  padding: '0.8rem',
                },
              }}
              cancelButtonProps={{
                style: {
                  padding: '0.8rem',
                },
              }}
              okText={<Typography.Title style={{ color: 'white', marginBottom: 0, }}
              level={5}>{t('ordersImportPage.table.ok')}</Typography.Title>}
              cancelText={<Typography.Title style={{marginBottom: 0}} level={5}>{t('ordersImportPage.table.cancel')}</Typography.Title>}
              title={<Typography.Title level={4}>{t('ordersImportPage.table.confirmSave')}</Typography.Title>}
              onConfirm={() => save(record.key)}
            >
                  <img className='edit-img' src='/assets/icons/import/check-square.svg' alt='check-square' />
                </Popconfirm>
        {/*<Typography.Link onClick={() => save(record.key)} style={{ marginRight: 8 }}>*/}
        {/*</Typography.Link>*/}
          <img onClick={cancel} className='edit-img' src='/assets/icons/import/close-square.svg' alt='check-square' />
      </span>
        ) : (
          <div>
            <Typography.Link disabled={editingKey !== ''} onClick={() => editOrder(record)}>
                <img className='edit-img' src='/assets/icons/import/edit.svg' alt='edit' />
            </Typography.Link>
            {
              (hasAnyRole([Roles.Admin])) && (
                <Popconfirm
                  okButtonProps={{
                    style: {
                      padding: '0.8rem',
                    },
                  }}
                  cancelButtonProps={{
                    style: {
                      padding: '0.8rem',
                    },
                  }}
                  okText={<Typography.Title style={{ color: 'white' }}
                  level={5}>{t('ordersImportPage.table.ok')}</Typography.Title>}
                  cancelText={<Typography.Title level={5}>{t('ordersImportPage.table.cancel')}</Typography.Title>}
                  title={<Typography.Title level={4}>{t('ordersImportPage.table.confirmDelete')}</Typography.Title>}
                  onConfirm={() => deleteOrder(record)}
                >
                  <img className='edit-img' src='/assets/icons/import/close.svg' alt='edit' />
                </Popconfirm>
              )
            }

          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: ImportTableOrder) => ({
        record,
        inputType: 'text',  // Can be adjusted for number input, etc.
        dataIndex: col.dataIndex,
        title: col.title,
        form: form,
        editing: isEditing(record),
        setData: setTableOrders,
        cities: citiesData?.cities,
      }),
    };
  });
  const [confirmModal, setConfirmModal] = useState({
    title: '',
    show: false,
    handleSubmit: async () => {},
    handleClose: () => {}
  })

  function handleNextStep () {
    setImportForm({
      ...importForm,
      step: importForm.step + 1
    })
  }

  return (
    <Form form={form} component={false}>
      <Table<ImportTableOrder>
        components={{
          body: { cell: EditableCell },
        }}
        bordered
        dataSource={tableOrders}
        columns={mergedColumns}
        rowClassName={record => `import-row ${getRowClassName(record)}`}
        pagination={{ onChange: cancel }}
      />
      <div className='d-flex justify-content-between' style={{padding: '0.8rem 0'}}>
        <Button
          disabled={importForm?.brokenOrders?.length > 0}
          style={{fontSize: '0.85rem', padding: '1.25rem 1.5rem'}}
          type={'primary'}
          onClick={() => setConfirmModal({
            title: t('ordersImportPage.confirm'),
            show: true,
            handleSubmit: async () => {
              const createdOrders = await getReadyToImportOrders(importForm?.type, tableOrders, importForm?.orders, citiesData?.cities)
              if(importForm?.type === 'HH') {
                await OrderService.multipleChangeStatus(createdOrders?.orderItems?.map(order => order?.id), 'CONFIRM_NEW_ORDER')
              }
              else if(importForm?.type === 'Alseko') {
                await OrderService.multipleChangeStatus(createdOrders?.orderItems?.map(order => order?.id), 'WAREHOUSE_FINISH_SORTING')
              }
              return createdOrders
            },
            handleClose: () => {
              setConfirmModal(prev => ({
                ...prev,
                show: false
              }))
            }
          })}
        >{t('ordersImportPage.buttons.create')}</Button>
        <Button
          style={{fontSize: '0.85rem', padding: '1.25rem 1.5rem'}}
          color={'danger'}
          onClick={() => setConfirmModal({
            title: t('ordersImportPage.cancel'),
            show: true,
            handleSubmit: async () => {
              setImportForm({
                step: 0,
                type: "",
                fileName: "",
                fileSize: '',
                orders: [],
                brokenOrders: [],
                initialOrders: []
              })
              navigate('/orders')
            },
            handleClose: () => {
              setConfirmModal(prev => ({
                ...prev,
                show: false
              }))
            }
          })}
        >{t('ordersImportPage.buttons.cancel')}</Button>
      </div>
      {confirmModal?.show && (
        <OrderImportConfirmModal key={confirmModal} title={confirmModal?.title} handleClose={confirmModal?.handleClose} onSubmit={confirmModal?.handleSubmit} handleNextStep={handleNextStep}/>
        )}
    </Form>
  );
});

interface OrderImportTableProps {
  citiesData: CityApiPageResponse
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  cities: City[];
  setData: (value: (prev) => string[] | number []) => void;
  record: TableOrder;
  index: number;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
                                                                              editing,
                                                                              dataIndex,
                                                                              title,
                                                                              record,
                                                                              cities,
                                                                              data,
                                                                              form,
                                                                              setData,
                                                                              children,
                                                                              ...restProps
                                                                            }) => {
  const {t} = useTranslation()
  const rules = (dataIndex === 'addressComments' || dataIndex === 'phone') // Example of an optional field
    ? []
    : [{
    required: true, message: t('ordersImportPage.table.emptyField',
        { title: title })
  }]; // Required fields
  const [inputValue, setInputValue] = useState<string>((record && dataIndex) ? record[dataIndex] : '');
  const currCity = cities?.find(city => city?.name === record?.city);
  const handleCityChange = (option: SelectOption, record: ImportTableOrder) => {
    const updatedData = data?.map(order => {
      if (order.id === record.id) {
        return {
          ...order,
          city: option.label,
        };
      }
      return order;
    });

    // Update the table data
    updatedData && setData(updatedData);

    // Update form field manually since the select component doesn't directly update the form field
    form.setFieldsValue({
      ...record,
      city: option.label,
    });
  };

  const getInput = (dataIndex) => {
    switch (dataIndex) {
      case 'city':
        return <div style={{ width: '100%' }}>
          <CityAsyncSelect
            key={currCity}
            onChange={handleCityChange}
            defaultValue={{ label: currCity?.name, value: currCity?.id }}
          />
        </div>;
      case 'phone':
        return <PhoneInputWithoutFormik style={{fontSize: '0.8rem'}} required={false} initialValue={record?.phone} label={""} id={record?.id} name={dataIndex} onMaskChange={phone => setInputValue(phone)}/>
      default:
        return <Input className={'import-cell'} value={inputValue} onChange={e => setInputValue(e?.target?.value)}
                   placeholder={record[dataIndex]} />
    }
  };

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}  // Ensure this matches the dataIndex
          style={{ margin: 0, width: '100%' }}
          rules={rules}
        >
          {getInput(dataIndex)}

        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default OrderImportTable;
