import React from 'react'
import { Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { ReportCourierDeliveryDynamics } from '@/common/models/report'

const ReportCourierDeliveryDynamicsTable = ({ reportData }: ReportCourierDeliveryDynamicsTableProps) => {
    const { t } = useTranslation()

	reportData?.data?.map(row => (row.deliveriesPerDate.sort((a,b) => a.date.localeCompare(b.date))))

	const tableHeaders = reportData?.data[0]?.deliveriesPerDate.map(deliveryDate =>
                <th>{deliveryDate.date}</th>)

        return (
            <div>
                <Table bordered striped size="sm">
                    <thead>
                        <tr>
                            <th>{t('reportPage.labels.courier-delivery-dynamics.name')}</th>
                            {tableHeaders}
                        </tr>
                </thead>
                <tbody>
                    {reportData?.data?.map(row => (
                        <tr>
                            <td>{row.firstName + ' ' + row.lastName}</td>
                            {row.deliveriesPerDate.map(deliveryCount =>
                                             <td>{deliveryCount.count}</td>)}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

interface ReportCourierDeliveryDynamicsTableProps {
    reportData: ReportCourierDeliveryDynamics,
}

export default ReportCourierDeliveryDynamicsTable