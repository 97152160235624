import React, { FC } from 'react';
import { Modal, Button, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';


import ImportTypeSelect from '@/pages/order-import/components/ImportTypeSelect';
import { importTypesOptions } from '@/pages/order-import/importTypesOptions';
import useImport from '@/common/hooks/useImport';

const {Title, Text} = Typography

const OrderImportTypeModal: FC<OrderImportTypeModalProps> = observer(({handleNextStep, handleClose}) => {
  const {importForm} = useImport();

  const { t } = useTranslation();
  return (
    <Modal
      open={importForm?.step === 0}
      className={"import-first-step-modal"}
      onCancel={handleClose}
      title={<Title level={3}>{t('ordersImportPage.forms.title')}</Title>}
      footer={
        <div id={"first-step-footer"}>
          <Button
            disabled={importForm?.type === ""}
            className="footer-button"
            type={"primary"}
            onClick={handleNextStep}
          >{t('ordersImportPage.forms.next')}</Button>
        </div>
      }
    >
      <Title level={5}>{t("ordersImportPage.forms.type")}</Title>
      <ImportTypeSelect
        className={'import-dropdown'}
        disabled={importForm?.step !== 0}
        style={{width: "45%"}}
        placeHolder={<Text>{importTypesOptions.find(option => option.value === importForm?.type)?.label || t("ordersImportPage.forms.typeDropdown")}</Text>}
      />
    </Modal>
  );
})


interface OrderImportTypeModalProps {
  handleNextStep: () => void,
  handleClose: () => void
}

export default OrderImportTypeModal;