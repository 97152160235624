import React, { useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'

import ReportView from './components/ReportView'
import ReportSelectorBar from './components/ReportSelectorBar'
import DefaultButton from '@/components/buttons/DefaultButton'
import ReportService from '@/common/api/ReportService'
import { DateRange } from '@/common/models/util'

const ReportPage = () => {
    const { t } = useTranslation()

    const [reportType, setReportType] = useState<string>('cell-load')
    const [dateRange, setDateRange] = useState<DateRange>()

    const queryClient = useQueryClient()
    const { data, isFetching, error, refetch } = useQuery<any>('reports',
        () => ReportService.getReport(reportType, dateRange), { enabled: false })

    return (
        <div className="pt-3 h-100">

            {/* Header */}
            <div>
                <p className="heading-2">{t('reportPage.labels.reports')}</p>
            </div>

            {/* Parameters */}
            <div className="my-4">
                <div className="py-2">
                    <span className="heading-font">{t('reportPage.labels.reportParams')}</span>
                </div>
                <ReportSelectorBar
                    onReportTypeChange={type => {
                        setReportType(type)
                        queryClient.removeQueries('reports')
                    }}
                    onDateChange={(dateRange) => setDateRange(dateRange)}
                />
                <div className="py-2">
                    <DefaultButton
                        variant="light"
                        onClick={() => refetch()}
                        isLoading={isFetching}
                        disabled={!dateRange}
                    >
                        <span>{t('reportPage.buttons.sendBtn')}</span>
                    </DefaultButton>
                </div>
            </div>
            <div style={{ height: '70%', overflowY: 'auto' }}>
                <ReportView
                    reportType={reportType}
                    isLoading={isFetching}
                    error={error}
                    data={data}
                />
            </div>
        </div>
    )
}

export default ReportPage