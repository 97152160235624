import React, {Suspense} from "react";
import {Spinner} from "react-bootstrap";

import OrderSection from "./components/OrderSection"
import UserSection from "./components/UserSection"
import useCourier from "@/common/hooks/useCourier"

const TelegramOrderViewPage = () => {
    const { telegramData } = useCourier()

    return (
        <div>
            <Suspense fallback={<Spinner/>}>
                <UserSection user={telegramData?.user} />
                <OrderSection />
            </Suspense>
        </div>
    )
}

export default TelegramOrderViewPage
