import { useMemo } from "react"
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useQuery } from "react-query"
import { ResponseError } from "superagent"

import { OrderApiPageResponse } from "../models/api/v0/order.dto"
import { Order } from "../models/order"
import OrderService from "@/telegram/api/OrderService"

type Refetch = <TPageData>(options?: RefetchOptions & RefetchQueryFilters<TPageData>) => Promise<QueryObserverResult<OrderApiPageResponse, unknown>>

type UseFetchOrders = {
    orders: Order[],
    isFetching: boolean,
    refetch: Refetch
}

const useFetchOrders = (filterQuery: string, onError: (err: ResponseError) => void): UseFetchOrders => {
    const { 
        data, 
        isFetching, 
        refetch
    } = useQuery<OrderApiPageResponse>(
        ['courier-orders', filterQuery], 
        () => OrderService.getAll({ deliveryStatuses: filterQuery }),
        { onError }
    )

    const orders = useMemo<Order[]>(() => {
        if (!data) {
            return []
        }

        const groupedOrders = data?.orders?.reduce((acc, item) => {
            const { orderId, status, createDate, deliverySender } = item
            const key = `${orderId}-${status}`

            if (!acc[key]) {
                acc[key] = {
                    id: orderId,
                    createDate,
                    status,
                    deliverySender,
                    orderItems: [],
                }
            }

            acc[key].orderItems.push(item)
            return acc
        }, {} as { [key: string]: Order })

        return Object.values(groupedOrders)
    }, [data])

    return { orders, isFetching, refetch } as const
}

export default useFetchOrders