import {makeAutoObservable} from "mobx";

import {Persistable} from "../storage/persistable";
import { importForm, SortOption } from '@/common/models/util';
import {OrderItem, OrderSearchQuery} from "@/common/models/order";

class OrderStore implements Persistable{
    persistedSortOptions: SortOption[] = []
    persistedFilterQuery: OrderSearchQuery = {}
    // TODO: Make dto to take only needed fields after implementing all functions
    selectedOrders: OrderItem[] = []
    importForm: importForm = {
        step: 0,
        type: "",
        fileName: "",
        fileSize: '',
        orders: [],
        brokenOrders: [],
        initialOrders: []
    }
    constructor() {
        makeAutoObservable(this, {}, {autoBind: true})
        this.loadFromLocalStorage()
    }

    setImportForm(orders) {
        this.importForm = orders;
        localStorage.setItem('importForm', JSON.stringify(this.importForm));
    }
    setSelectedOrders(orders) {
        this.selectedOrders = orders
        localStorage.setItem('selectedOrders', JSON.stringify(this.selectedOrders))
    }

    persistSortOptions(options) {
        this.persistedSortOptions = options
        localStorage.setItem('sortOptions', JSON.stringify(this.persistedSortOptions))
    }

    persistFilterQuery(options) {
        this.persistedFilterQuery = options
        localStorage.setItem('filterQuery', JSON.stringify(this.persistedFilterQuery))
    }



    loadFromLocalStorage(): void {
        const sortOptions = localStorage.getItem('sortOptions')
        const filterQuery = localStorage.getItem('filterQuery')
        const selectedOrders = localStorage.getItem('selectedOrders')
        const importForm = localStorage.getItem('importForm')

        if (sortOptions) {
            this.persistedSortOptions = JSON.parse(sortOptions)
        }
        if (importForm && importForm !== "undefined") {
            this.importForm = JSON.parse(importForm)
        }
        if(filterQuery){
            this.persistedFilterQuery = JSON.parse(filterQuery)
        }
        if(selectedOrders){
            this.selectedOrders = JSON.parse(selectedOrders)
        }
    }
}

const orderStore = new OrderStore()

export default orderStore