import { ruOrdersImport } from '@/common/constants/locales/ru/ordersImport';
import { ruOptions } from '@/common/constants/locales/ru/options';
import { ruOrdersPage } from '@/common/constants/locales/ru/ordersPage';
import { ruCommon } from '@/common/constants/locales/ru/common';
import { ruTelegram } from '@/common/constants/locales/ru/telegram';
import { ruReports } from '@/common/constants/locales/ru/reports';
import { ruOrderCreate } from '@/common/constants/locales/ru/orderCreate';

export const ruTranslation = {
  translation: {
    ...ruCommon,
    orderCreate: ruOrderCreate,
    ordersImportPage: ruOrdersImport,
    options: ruOptions,
    ordersPage: ruOrdersPage,
    telegram: ruTelegram,
    reportPage: ruReports,
  },
};