import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import {Tab, Tabs} from 'react-bootstrap'
import {useTranslation} from "react-i18next";

import {CourierStatusMode, Statuses} from "@/common/constants/statuses";
import OrderList from './order/OrderList'
import OrderItemList from './order-item/OrderItemList'

const OrderSection = observer(() => {
    const { t } = useTranslation()

    const [selectionTab, setSelectionTab] = useState(CourierStatusMode.PICKUP)

    const handleTabChange = (tab: CourierStatusMode) => {
        setSelectionTab(tab)
    }

    const tabComponents = {
        [CourierStatusMode.PICKUP]: <OrderList />,
        [CourierStatusMode.DELIVERY]: <OrderItemList />,
    }

    return (
        <div>
            <div className="mt-3">
                <Tabs
                    className="my-2"
                    id="courier-orders-tab"
                    activeKey={selectionTab}
                    onSelect={handleTabChange}
                    fill
                >
                    {Object.keys(Statuses.courierAppStatusLists).map((tabKey, index) => (
                        <Tab 
                            key={index} 
                            eventKey={tabKey} 
                            tabClassName={selectionTab === tabKey
                                ? "text-primary" 
                                : "bg-dark bg-opacity-10 text-black"
                            }
                            title={t(`courierTabs.${tabKey}`)}
                        />
                    ))}
                </Tabs>
            </div>
            {tabComponents[selectionTab]}
        </div>
    )
})

export default OrderSection