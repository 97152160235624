import { FC } from "react"
import WebApp from "@twa-dev/sdk"

import { Platform } from "@/common/constants/platform"
import { formatNumberMasked } from "@/common/utils/utils"

const PhoneLink: FC<PhoneLinkProps> = ({ phone, className = '' }) => {
    const { platform } = WebApp
    const maskedPhone: string = formatNumberMasked(phone)
    const phoneRef: string = (platform === Platform.ANDROID ? '//' : '') + maskedPhone

    const handlePhoneClick = (e: any) => {
        e.preventDefault()
        window.open(`tel:${phoneRef}`)
    }

    return (
        <a 
            className={className}
            href={`tel:${phoneRef}`}
            onClick={handlePhoneClick}
        >
            {maskedPhone}
        </a>
    )
}

interface PhoneLinkProps {
    phone: string,
    className?: string
}

export default PhoneLink