import React from 'react'
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const LoadingList = () => {
    const { t } = useTranslation()

    return (
        <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="d-flex flex-column align-items-center">
                <Spinner />
                <span className="btn-text my-3">{t('telegram.pages.orderAction.progressLabels.loading')}</span>
            </div>
        </div>
    )
}

export default LoadingList