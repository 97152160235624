import React from 'react'
import { useTranslation } from 'react-i18next'

const NoDataList = () => {
    const { t } = useTranslation()

    return (
        <div className="border-dotted border-rounded-2 d-flex justify-content-center align-items-center vh-100">
            <div className="d-flex flex-column align-items-center my-5">
                <span className="text-muted heading-2 mb-1">
                    👀
                </span>
                <span className="heading-font text-muted">{t('telegram.pages.orderAction.progressLabels.noData')}</span>
            </div>
        </div>
    )
}

export default NoDataList