import React from 'react'
import { useTranslation } from 'react-i18next'

const UserSection = ({ user }: UserSectionProps) => {
    const { t } = useTranslation()

    if (!user) {
        return
    }

    return (
        <div className="py-2">
            <span className="heading-font">{t('telegram.pages.orderAction.user.greeting')}, {user && user.first_name} 👋</span>
        </div>
    )
}

interface UserSectionProps {
    user?: any,
}

export default UserSection