import React from 'react';
import { observer } from 'mobx-react-lite';
import OrderImportTypeModal from '@/pages/order-import/components/modals/OrderImportTypeModal';
import OrderImportFileModal from '@/pages/order-import/components/modals/OrderImportFileModal';
import useImport from '@/common/hooks/useImport';
import { useQuery } from 'react-query';
import { CityService } from '@/common/api/CityService';

const OrderImportWrapper = observer(({showImportModal, setShowImportModal}) => {
  const {importForm, setImportForm} = useImport();
  const { data: citiesData } = useQuery(['getCitiesForImportPage'],
    () => CityService.getAll({ size: 100 }),
    {
      enabled: true,
    },
  );
  const forms = [
    <OrderImportTypeModal key={importForm} cities={citiesData?.cities} handleClose={handleClose} handleNextStep={handleNextStep}/>,
    <OrderImportFileModal key={importForm} cities={citiesData?.cities} handleClose={handleClose} handleNextStep={handleNextStep} handleBackStep={handleBackStep}/>
  ];
  function handleBackStep() {
    setImportForm({
      step: 0,
        type: "",
      fileName: "",
      fileSize: '',
      orders: [],
      brokenOrders: [],
      initialOrders: []
    })
  }
  //TODO: поменять обратно orders на []
  function handleClose() {
    setImportForm({
      step: 0,
      type: "",
      fileName: "",
      fileSize: '',
      orders: [],
      brokenOrders: []
    })
    setShowImportModal(false)
  }
  function handleNextStep () {
    setImportForm({
      ...importForm,
      step: importForm.step + 1
    })
  }

  return (
    <div>
      {showImportModal && forms[importForm.step]}
    </div>
  );
})

export default OrderImportWrapper;