import superagent from "superagent"

const apiRoot = process.env.REACT_APP_API_ROOT

const encodeData = (value: string) => {
    const transformToChars = (data: string, byte: number) => data + String.fromCharCode(byte)
    return btoa(new TextEncoder().encode(value).reduce(transformToChars, ''))
}

const telegramDataInjectorPlugin = (request: superagent.Request) => {
    request.set('Telegram-Authorization', encodeData(localStorage.getItem('user-data')))
    request.set('Telegram-User-Phone', localStorage.getItem('user-phone'))
}

const handleResponse = (response: superagent.Response) => {
    return response.body
}

const requests = {
    get: (url: string, queryParams?: any) =>
        superagent
            .get(`${apiRoot}${url}`)
            .use(telegramDataInjectorPlugin)
            .query(queryParams)
            .then(handleResponse),
    post: (url: string, body?: any) =>
        superagent
            .post(`${apiRoot}${url}`)
            .use(telegramDataInjectorPlugin)
            .send(body)
            .then(handleResponse),
    put: (url: string, body?: any) =>
        superagent
            .get(`${apiRoot}${url}`)
            .use(telegramDataInjectorPlugin)
            .send(body)
            .then(handleResponse),
    delete: (url: string) =>
        superagent
            .delete(`${apiRoot}${url}`)
            .use(telegramDataInjectorPlugin)
            .then(handleResponse),
}

export default requests
