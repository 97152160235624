import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BoxSeamFill, ChevronDown, ChevronUp } from "react-bootstrap-icons"
import {useFormikContext} from "formik";
import {Row} from "react-bootstrap";

import { OrderCreateData } from "@/common/models/order"
import { OrderCreateOwnerRole } from "@/common/constants/order"
import Input from "@/components/controls/input/Input"
import Heading from "@/components/typography/Heading"
import CityAsyncSelect from "@/components/controls/select/CityAsyncSelect"
import { FormikSetter, SelectOption } from "@/common/models/util"
import AddressAsyncSelect from "@/pages/onboard/components/AddressAsyncSelect";
import CompanyAsyncSelect from "@/pages/order-create/components/forms/CompanyAsyncSelect";
import InputPhoneMasked from "@/components/controls/input/InputPhoneMasked"
import { SuggestCompanyPart } from "@/common/models/company"
import { AddressData } from "@/common/models/address"
import { useTranslation } from 'react-i18next';

const DeliveryPartyForm = ({ disabled = false, type, role, highlightFor, initialValues }: DeliveryPartyFormProps) => {
    const { t } = useTranslation()
    const [isAutofill, setIsAutofill] = useState(type.includes(highlightFor))
    const [show, setShow] = useState<boolean>(!isAutofill)
    const {values, setFieldValue } = useFormikContext<OrderCreateData>()
    const asyncSelectRef = useRef(null)
    const [selectedCompany, setSelectedCompany] = useState(null)
    useEffect(() => {
        setIsAutofill(type.includes(highlightFor))
    }, [highlightFor, type, initialValues])

    const getCityDefaultValue = useCallback((): SelectOption | null => {
        if (!initialValues) {
            return
        }
        if(role === 'Receiver'){
            return {
                value: selectedCompany?.city?.id || initialValues.orderItems[0].deliveryReceiver.address.city?.id,
                label: selectedCompany?.city?.name || initialValues.orderItems[0].deliveryReceiver.address.city?.name,
            }
        } else {
            return {
                value: selectedCompany?.city?.id || initialValues.deliverySender.address.city?.id,
                label: selectedCompany?.city?.name || initialValues.deliverySender.address.city?.name,
            }
        }
    }, [initialValues, role, selectedCompany])
    const getCompanyDefault = (): SuggestCompanyPart | null => {
        if(selectedCompany)
            return selectedCompany
        return role === 'Receiver' ? {
            ...values.orderItems[0].deliveryReceiver.company,
            address: {...values.orderItems[0].deliveryReceiver.company.address}
        } : {
            ...values.deliverySender.company,
            address: {...values.deliverySender.company.address}
        }
    }
    const getBuildingDefault = (): AddressData | null => {
        return role === 'Receiver' ? {
            building: selectedCompany?.building ?? values.orderItems[0].deliveryReceiver?.address?.building,
            street: selectedCompany?.street ?? values.orderItems[0].deliveryReceiver?.address?.street,
            city: values.orderItems[0].deliveryReceiver?.address?.city,
            point: selectedCompany?.address?.point ?? values.orderItems[0].deliveryReceiver?.address?.point ?? null,
        } : {
            building: selectedCompany?.building ?? values.deliverySender?.address?.building,
            street: selectedCompany?.street ?? values.deliverySender?.address?.street,
            city: selectedCompany?.city ?? {
                id: selectedCompany?.city?.id ?? values.deliverySender?.address?.city?.id,
                name: selectedCompany?.city?.name ?? values.deliverySender?.address?.city?.name,
            },
            point: selectedCompany?.address?.point ?? values.deliverySender?.address?.point ?? null,
        }
    }
    const handleAddressChange = useCallback(
      (address: AddressData, callback: FormikSetter) => {
          callback(`${type}.address.city.id`, address?.cityId ?? getCityDefaultValue()?.value);
          callback(`${type}.address.city.name`, address?.cityName ?? getCityDefaultValue()?.label);
          callback(`${type}.address.building`, address?.building);
          callback(`${type}.address.street`, address?.street);
          callback(`${type}.address.point`, address?.point);
      },
      [getCityDefaultValue, type]
    );

    const handleCompanyChange = (company: SuggestCompanyPart, callback: FormikSetter) => {
        callback(`${type}.company.name`, company?.name)
        callback(`${type}.address.city.id`, Number(company?.city?.id))
        callback(`${type}.address.city.name`, company?.city?.name)
        callback(`${type}.address.street`, company?.address?.street)
        callback(`${type}.address.building`, company?.address?.building)
        callback(`${type}.address.apartment`, company?.address?.apartment)
        callback(`${type}.address.comment`, company?.address?.addressComment ?? values?.orderItems[0]?.deliveryReceiver?.address?.comment)
        callback(`${type}.contactName`, company?.contactName)
        callback(`${type}.address.point`, company?.address?.point)
        setSelectedCompany(company)
    }

    const handleCityChange = useCallback((option, setFieldValue) => {
        setFieldValue(`${type}.address.city.id`, Number(option.value))
        setFieldValue(`${type}.address.city.name`, option.label)
        setFieldValue(`${type}.address.street`, "")
        setFieldValue(`${type}.address.building`, "")
        setFieldValue(`${type}.address.apartment`, "")
        setFieldValue(`${type}.address.point`, null)
    }, [type])
    const partyAddress = role === 'Sender' ? (
      `${values?.deliverySender?.address?.street} ${values?.deliverySender?.address?.building}`
    ) : (
      `${values?.orderItems[0]?.deliveryReceiver?.address?.street} ${values?.orderItems[0]?.deliveryReceiver?.address?.building}`
    )
    return (
        <div>
            <div className="py-3">
                <div
                    className={`d-flex py-2 px-3 border border-rounded-1 cursor ${isAutofill ? 'bg-primary text-white' : 'bg-light'}`}
                    onClick={() => setShow(!show)}
                >
                    <Heading
                        size="sm"
                        icon={<BoxSeamFill />}
                    >
                        {type === 'deliverySender' ? t('deliveryParty.sender') : t('deliveryParty.receiver')}
                        {isAutofill && (
                            <span className="mx-1 opacity-50">— {t('orderCreate.useData')}</span>
                        )}
                    </Heading>
                    <div className="d-flex align-items-center ms-auto">
                        {show ? (
                            <ChevronUp />
                        ) : (
                            <ChevronDown />
                        )}
                    </div>
                </div>
            </div>
            {show && (
                <div className="w-100 d-flex flex-column justify-content-center">
                    {
                        !disabled ? (
                            <Row
                                className="mb-3"
                            >
                                <span>{t('orderCreate.form.companyName')}<span className="text-danger">*</span></span>
                                <CompanyAsyncSelect
                                    onSelect={(company) => handleCompanyChange(company, setFieldValue)}
                                    onInputChange={value => setFieldValue(`${type}.company.name`, value)}
                                    prevCompany={getCompanyDefault()}
                                />
                            </Row>
                        ) : (
                            <Input
                                className="py-2"
                                id={`${type}.company.name`}
                                label={t('orderCreate.form.companyName')}
                                name={`${type}.company.name`}
                                placeholder={t('orderCreate.form.companyName')}
                                disabled={disabled}
                                required
                            />
                        )
                    }

                    <Input
                        className="py-2"
                        id={`${type}.company.bin`}
                        label={t('orderCreate.form.bin')}
                        name={`${type}.company.bin`}
                        disabled={disabled}
                    />
                    <div>
                        <div>
                            <span>{t('orderCreate.form.city')}<span className="text-danger">*</span></span>
                        </div>
                        <div>
                            <CityAsyncSelect
                                readOnly={disabled}
                                key={selectedCompany?.city?.id}
                                name={role}
                                defaultValue={getCityDefaultValue()}
                                onChange={(option) => handleCityChange(option, setFieldValue)}
                            />
                        </div>
                    </div>
                    {
                        ( !disabled ? (
                            <Row className="mb-3 mt-3">
                                <span>{t('orderCreate.form.address')}<span className="text-danger">*</span></span>
                                <AddressAsyncSelect
                                    key={`${selectedCompany?.address?.street} ${selectedCompany?.address?.building}`}
                                    disabled={disabled}
                                    prevAddress={getBuildingDefault()}
                                    onSelect={(address) => {
                                        handleAddressChange(address,setFieldValue)
                                        handleAddressChange(address,setFieldValue)
                                    }}
                                    onInputChange={(street, building) => {
                                        setFieldValue(`${type}.address.street`, street);
                                        setFieldValue(`${type}.address.building`, building);
                                    }}
                                    validate={() => {
                                        asyncSelectRef?.current?.focus()
                                    }}
                                />
                            </Row>
                        ) : (
                            <Input
                                className="py-2"
                                id={`${type}.address.address`}
                                label={t('orderCreate.form.address')}
                                name={`${type}.address.address`}
                                value={partyAddress}
                                placeholder={""}
                                disabled={disabled}
                                required
                            />
                        ))
                    }
                    <Input
                        innerRef={asyncSelectRef}
                        className="py-2"
                        id={`${type}.address.apartment`}
                        label={t('orderCreate.form.apartment')}
                        name={`${type}.address.apartment`}
                        placeholder={""}
                        disabled={disabled}
                    />
                    <Input
                        className="py-2"
                        id={`${type}.address.comment`}
                        label={t('orderCreate.form.addressComments')}
                        name={`${type}.address.comment`}
                        placeholder={""}
                        as={"textarea"}
                        style={{minHeight: "3.5rem"}}
                        disabled={disabled}
                    />
                    <InputPhoneMasked
                        className="py-2"
                        id={`${type}.phone`}
                        name={`${type}.phone`}
                        label={t('orderCreate.form.phone')}
                        disabled={disabled}
                        required
                        onMaskChange={(value: string) => {
                            setFieldValue(`${type}.phone`, value)
                        }}
                    />
                    <Input
                        className="py-2"
                        id={`${type}.contactName`}
                        label={t('orderCreate.form.contactName')}
                        name={`${type}.contactName`}
                        placeholder={""}
                        disabled={disabled}
                    />
                </div>
            )}
        </div>
    )
}

interface DeliveryPartyFormProps {
    role: OrderCreateOwnerRole,
    type: DeliveryPartyType,
    highlightFor: OrderCreateOwnerRole,
    disabled?: boolean,
    /**
     * Needed to set city, since it's not a formik component.
     */
    initialValues?: any,
    setFieldValue: (field: string, value: any) => Promise<any>,
}

type DeliveryPartyType = 'deliverySender' | 'orderItems[0].deliveryReceiver'

export default DeliveryPartyForm
