import React, { useCallback, useRef, useState } from 'react';
import {Formik, Form} from "formik";
import {Col, Row, Spinner} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

import {CompanyModalSchema, getInitialValues} from "@/pages/onboard/components/Form/CompanyValidation";
import Input from "@/components/controls/input/Input";
import InputPhoneMasked from '@/components/controls/input/InputPhoneMasked'
import { Address, City } from "@/common/models/address"
import {CompanyService} from "@/common/api/CompanyService";
import DefaultButton from "@/components/buttons/DefaultButton";
import useAuth from "@/common/hooks/useAuth";
import {checkIfEmpty, getPointIfPresent} from "@/common/utils/utils";
import CityAsyncSelect from '@/components/controls/select/CityAsyncSelect'
import MapComponent from "@/pages/onboard/components/MapComponent";
import AddressAsyncSelect from "@/pages/onboard/components/AddressAsyncSelect";
import { FormikSetter, SelectOption } from '@/common/models/util'
import { useTranslation } from 'react-i18next';


const OnboardForm = () => {
    const {t} = useTranslation()
    const {user, refetchUser} = useAuth()
    const initialValues = getInitialValues(user?.company, user?.company?.contactPhone)
    const asyncSelectRef = useRef(null)

    const [selectedAddress, setSelectedAddress] = useState(user?.company?.addresses[0])
    const [city, setCity] = useState<City | undefined>(user?.company?.city)

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const navigate = useNavigate()

    const handleCityChange = useCallback((cityOption: SelectOption, callback: FormikSetter, clean = false) => {
        if (!clean) {
            callback("city", cityOption.label)
            callback(`street`, "")
            callback(`building`, "")

            setCity({ id: cityOption.value, name: cityOption.label })
            setSelectedAddress(prev => ({
                ...prev,
                street: "",
                building: "",
                point: null
            }))
        } else {
            callback("city", null)
            setCity(null)
        }
    }, [setCity])

    const handleSubmit = async (values) => {
        try {
            setError(() => null)
            setIsLoading(true)
            const updatedCompany = {
                name: values.name,
                bin: values.bin,
                city: {
                    "id": city.id,
                    "name": city.name
                },
                streetName: selectedAddress?.street ?? values?.street,
                houseNumber: selectedAddress?.building ?? values.building,
                point: getPointIfPresent(selectedAddress?.point?.lat, selectedAddress?.point?.lon),
                apartment: values.apartment,
                contactPhone: values.contactPhone,
                addressComments: values.addressComments
            }
            if (user.company) {
                await CompanyService.updateCompany(user.company.id, updatedCompany)
            } else {
                await CompanyService.createClientCompany(updatedCompany)
            }
            refetchUser()
                .then(() => navigate('/'))
        } catch (e) {
            setIsLoading(true)
            setError(() => e?.response?.body)
        } finally {
            setIsLoading(false)
        }
    }

    const handleAddressChange = useCallback((address: Address, callback: FormikSetter) => {
        if(address) {
            callback("building", checkIfEmpty(address?.building))
            callback("street", checkIfEmpty(address?.street ?? user?.company?.streetName))
        }
        setSelectedAddress(address)
    }, [user?.company?.streetName])

    return (
        <Formik initialValues={initialValues}
                validationSchema={CompanyModalSchema.custom}
                onSubmit={(values) => handleSubmit(values)}
        >
            {({values, setFieldValue, errors}) =>
                (<Form key={"CompanyModal"}>
                    <div className="w-75">
                        <Row className="d-flex justify-content-between">
                            <Col md={6} className="d-flex flex-column justify-content-between">
                                <Row
                                    className="w-100 d-flex mb-1"
                                >
                                    <span>{t('orderCreate.form.city')}<span className="text-danger">*</span></span>
                                    <div className="my-1">
                                        <CityAsyncSelect
                                            defaultValue={{ value: city?.id, label: city?.name }}
                                            name="city"
                                            onChange={(option) => handleCityChange(option, setFieldValue)}
                                        />
                                    </div>
                                </Row>

                                <Row
                                    className="my-2 mb-3 w-100"
                                >
                                    <span>{t('orderCreate.form.address')}<span className="text-danger">*</span></span>
                                    <AddressAsyncSelect
                                      prevAddress={{
                                            ...user?.company?.addresses[0],
                                            street: values.street,
                                            city: city,
                                            building: values?.building
                                        }}
                                        error={errors?.building}
                                        onInputChange={(street, building) => {
                                            setFieldValue('street', street)
                                            setFieldValue('building', building)
                                        }}
                                        onSelect={(address) => handleAddressChange(address, setFieldValue)}
                                        validate={() => {
                                            asyncSelectRef?.current?.focus()
                                        }}
                                    />

                                </Row>
                                {selectedAddress?.point?.lat && selectedAddress?.point?.lon ? (
                                    <Row
                                        className="w-100 my-2 mb-3"
                                        style={{
                                            maxWidth: "100%",
                                            padding: "0 12px"
                                        }}
                                    >
                                        <MapComponent item={selectedAddress}/>
                                    </Row>
                                ): ""}

                                <Row className="w-100 my-2">
                                    <Input
                                        innerRef={asyncSelectRef}
                                        id={"apartment"}
                                        label={t('orderCreate.form.apartment')}
                                        name={"apartment"}
                                        placeholder={initialValues?.apartment}
                                    />
                                </Row>
                                <Row className="w-100 my-2">
                                    <Input
                                        id={"addressComments"}
                                        label={t('orderCreate.form.addressComments')}
                                        name={"addressComments"}
                                        as={"textarea"}
                                        style={{minHeight: "4rem"}}
                                        placeholder={initialValues?.addressComments}
                                    />
                                </Row>
                            </Col>
                            <Col md={6} className="d-flex flex-column justify-content-start align-items-center">
                                <Row className="w-100 d-flex">
                                    <Input
                                        id={"name"}
                                        label={t('orderCreate.form.companyName')}
                                        name={"name"}
                                        required
                                        placeholder={initialValues?.name}
                                    />
                                </Row>
                                <Row className="w-100 d-flex my-1">
                                    <Input
                                        id={"bin"}
                                        label={t('orderCreate.form.bin')}
                                        name={"bin"}
                                        placeholder={initialValues?.bin}
                                        required
                                    />
                                </Row>

                                <Row className="w-100 d-flex mb-3">
                                    <InputPhoneMasked
                                        className="py-2"
                                        id={`contactPhone`}
                                        name={`contactPhone`}
                                        label={t('orderCreate.form.phone')}
                                        required
                                        onMaskChange={(value: string) => {
                                            setFieldValue(`contactPhone`, value)
                                        }}
                                    />
                                </Row>




                                {error && !isLoading && (
                                    <Row className="w-100 mt-2 mb-2 d-block text-danger text-center">{error.errorMessage}</Row>
                                )}
                                {
                                    isLoading ? (<Spinner/>) : (
                                        <DefaultButton variant={"primary"} className="w-50"
                                                       submit>{t('orderCreate.confirmForm.forward')}</DefaultButton>
                                    )
                                }
                            </Col>
                        </Row>
                    </div>
                </Form>)
            }
        </Formik>
    );
};

export default OnboardForm;