import React, { createContext, useEffect } from 'react'
import WebApp from '@twa-dev/sdk'
import { Outlet, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Spinner } from 'react-bootstrap'

import courierStore from '../storage/courierStore'
import useCourier from '../hooks/useCourier'

export const CourierContext = createContext({
    courierStore,
})

const TelegramProvider = observer(() => {
    const { isPreparing, setIsPreparing, setTelegramData } = useCourier()
    const rootPath = '/telegram/courier'
    const location = useLocation()
    const { initData } = WebApp

    useEffect(() => {
        const userData = localStorage.getItem('user-data')
        const userPhone = localStorage.getItem('user-phone')

        if (initData && (!userData || !userPhone)) {
            const params = new URLSearchParams(initData);
            const rawObj = Object.fromEntries(params.entries());
            rawObj.user = JSON.parse(rawObj.user)

            localStorage.setItem('user-data', JSON.stringify(rawObj))
            setTelegramData(rawObj)

            if (location.pathname === rootPath) {
                const arg = location.search.split('=')[1]
                if (arg) {
                    localStorage.setItem('user-phone', arg)
                    return setIsPreparing(false)
                }
            }
        }

        if (userData) {
            return setIsPreparing(false)
        }
    })

    if (isPreparing) {
        return (
            <div>
                <Spinner />
            </div>
        )
    }

    if (!initData) {
        return (
            <span>Forbidden!</span>
        )
    }

    return (
        <CourierContext.Provider value={{ courierStore }}>
            <Outlet />
        </CourierContext.Provider>
    )
})

export default TelegramProvider