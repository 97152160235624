import React, {useRef, useState} from 'react';
import Select from "react-select";
import {Button} from 'react-bootstrap';

const customSelectStyles = {
    singleValue: (provided) => ({
        ...provided,
        width: "4rem",
        margin: "0 auto"

    }),
    option: (provided) => ({
        ...provided,
        textAlign: 'center'
    }),
    input: (provided) => ({
        ...provided,
        width: "4rem",
        margin: "0 auto",
    })
};

interface RouteCellSelectProps {
    items: any[],
    disabled?: boolean,
    defaultItem?: string,
    className?: string,
    onChange: (cell: string | null) => void,
    menuPlacement?: 'auto' | 'top'
}

const RouteCellSelect = ({items, disabled = false, className = "", defaultItem = "", onChange, menuPlacement = 'auto'}: RouteCellSelectProps) => {
    const selectRef = useRef(null)
    const [searchQuery, setSearchQuery] = useState(defaultItem ?? "")
    const options = items.map(buildOptions)
    const [selectedOption, setSelectedOption] = useState(defaultItem ? buildOptions(defaultItem)
        :
        {
            value: "",
            label: ""
        })

    function buildOptions (item) {
        return {
            label: item,
            value: item
        }
    }

    const handleInputChange = (query, {action}) => {
        if(action === "input-change"){
            setSearchQuery(query.toUpperCase())
        }
    }

    const handleOptionChange = (option, {action}) => {
        if (action === "select-option"){
            setSelectedOption(option)
            onChange(option?.value)
            setSearchQuery(option.label)
        }

        selectRef.current.blur()
        selectRef.current.focus()
        selectRef.current.blur()
    }

    const handleMenuOpen = () => {
        if(!options.find(option => option.value === selectRef.current.getValue()[0].value)){
            setSearchQuery("")
            setSelectedOption({
                value: "",
                label: defaultItem
            })
        }
    }

    const handleMenuClose = () => {
        if(selectedOption?.value === "" && options.find(option => option.value === selectRef.current.getValue()[0].value)){
            setSearchQuery(defaultItem)
            setSelectedOption(buildOptions(defaultItem))
        }
    }

    return (
        <div className={className}>
            {disabled ? (
                    <Button
                        disabled={true}
                        className={"w-100 btn-outline-primary text-dark"}
                    >{defaultItem ?? "Не выбрано"}</Button>
                ) : (
                <Select
                    ref={selectRef}
                    className="basic-single"
                    classNamePrefix="select"
                    styles={customSelectStyles}
                    isDisabled={disabled}
                    menuPlacement={menuPlacement}
                    value={selectedOption}
                    onMenuOpen={handleMenuOpen}
                    onMenuClose={handleMenuClose}
                    onChange={handleOptionChange}
                    inputValue={searchQuery}
                    onInputChange={handleInputChange}
                    blurInputOnSelect={true}
                    isSearchable={true}
                    name="color"
                    options={options}
                />
            )}
        </div>
    )
};



export default RouteCellSelect;