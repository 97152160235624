import { DeliveryPartyCreateData } from '@/common/models/deliveryParty';

export enum importTypes {
  'HH', 'Alseko'
}

export const importTypesOptions = [
  { value: 'HH', label: "HeadHunter" },
  { value: 'Alseko', label: "Алсеко" },
];

export const TypeExtensionMap = {
  'Alseko': {
    label: ".pdf",
    value: "application/pdf"
  },
  'HH': {
    label: ".xls, .xlsx",
    value: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  },
}

// TODO: Уточнить, какие данные указать для отправителя каждой компании
export const TypeSenderMap: DeliveryPartyCreateData = {
  'HH': {
    address: {
      city: {
        id: 2,
        name: "Алматы"
      },
      street: "",
      building: "",
      apartment: "",
      comment: "",
    },

    contactName: "ТОО HeadHunter",
    isCompany: true,
    phone: "",
    company: {
      name: "ТОО HeadHunter",
      bin: ""
    },
  },
  'Alseko': {
    phone: "",
    contactName: "Alseko",
    isCompany: true,
    company: {
      name: "Alseko",
      bin: ""
    },
    address: {
      city: {
        id: 2,
        name: "Алматы"
      },
      street: "",
      building: "",
      apartment: "",
      comment: "",
    },
  },
}

export const orders = [
  {
    "key": 85607358,
    "id": 85607358,
    "createDate": "2024-10-20T17:28:03.846744",
    "Sender": "ж7 компани",
    "Receiver": "",
    "city": "Астана",
    "addressString": "Астана, Актамберды жырау, 45, ",
    "addressComments": ""
  },
  {
    "key": 21357577,
    "id": 21357577,
    "createDate": "2024-08-29T21:08:51.463461",
    "Sender": "Googleguys",
    "Receiver": "ж7 компани",
    "city": "Шымкент",
    "addressString": "Шымкент, Нурсултана Назарбаева, 22, 1",
    "addressComments": ""
  },
  {
    "key": 72686102,
    "id": 72686102,
    "createDate": "2024-08-29T20:51:46.629071",
    "Sender": "ж7 компани",
    "Receiver": "fsfds",
    "city": "Астана",
    "addressString": "Астана, Актамберды жырау, 23, ",
    "addressComments": ""
  },
  {
    "key": 79855620,
    "id": 79855620,
    "createDate": "2024-08-29T17:51:14.49006",
    "Sender": "ж7 компани",
    "Receiver": "mukhaDzhon",
    "city": "Астана",
    "addressString": "Астана, Мангилик Ел, 224, 1",
    "addressComments": ""
  },
  {
    "key": 22353522,
    "id": 22353522,
    "createDate": "2024-08-29T17:48:38.180251",
    "Sender": "ж7 компани",
    "Receiver": "oldCompany",
    "city": "Шымкент",
    "addressString": "Шымкент, микрорайон Восток, 5, ",
    "addressComments": ""
  },
  {
    "key": 67317764,
    "id": 67317764,
    "createDate": "2024-08-29T17:46:30.296478",
    "Sender": "ж7 компани",
    "Receiver": "mukhaDzhon",
    "city": "Шымкент",
    "addressString": "Шымкент, жилмассив Нурсат, 35, 1",
    "addressComments": ""
  },
  {
    "key": 51348287,
    "id": 51348287,
    "createDate": "2024-08-29T17:44:41.844523",
    "Sender": "newCompany inc",
    "Receiver": "ж7 компани",
    "city": "Шымкент",
    "addressString": "Шымкент, Адырбекова, 2Б, 1",
    "addressComments": ""
  },
  {
    "key": 76130935,
    "id": 76130935,
    "createDate": "2024-08-29T16:39:58.326452",
    "Sender": "ж7 компани",
    "Receiver": "mukhaDzhon",
    "city": "Шымкент",
    "addressString": "Шымкент, жилмассив Нурсат, 35, 1",
    "addressComments": ""
  },
  {
    "key": 16444911,
    "id": 16444911,
    "createDate": "2024-08-29T15:47:08.781152",
    "Sender": "Google3",
    "Receiver": "ж7 компани",
    "city": "Шымкент",
    "addressString": "Шымкент, Адырбекова, 2Б, 1",
    "addressComments": ""
  },
  {
    "key": 38891122,
    "id": 38891122,
    "createDate": "2024-08-29T15:34:52.128519",
    "Sender": "ж7 компани",
    "Receiver": "vasya",
    "city": "Шымкент",
    "addressString": "Шымкент, Фариды Шариповой, 100, ",
    "addressComments": ""
  },

  {
    "key": 85407358,
    "id": 85607358,
    "createDate": "2024-10-20T17:28:03.846744",
    "Sender": "ж7 компани",
    "Receiver": "monter",
    "city": "Астана",
    "addressString": "Астана, Актамберды жырау, 45, ",
    "addressComments": ""
  },
  {
    "key": 21237577,
    "id": 21357577,
    "createDate": "2024-08-29T21:08:51.463461",
    "Sender": "Googleguys",
    "Receiver": "ж7 компани",
    "city": "Шымкент",
    "addressString": "Шымкент, Нурсултана Назарбаева, 22, 1",
    "addressComments": ""
  },
  {
    "key": 72236102,
    "id": 72686102,
    "createDate": "2024-08-29T20:51:46.629071",
    "Sender": "ж7 компани",
    "Receiver": "fsfds",
    "city": "Астана",
    "addressString": "Астана, Актамберды жырау, 23, ",
    "addressComments": ""
  },
  {
    "key": 791255620,
    "id": 79855620,
    "createDate": "2024-08-29T17:51:14.49006",
    "Sender": "ж7 компани",
    "Receiver": "mukhaDzhon",
    "city": "Астана",
    "addressString": "Астана, Мангилик Ел, 224, 1",
    "addressComments": ""
  },
  {
    "key": 22653522,
    "id": 22353522,
    "createDate": "2024-08-29T17:48:38.180251",
    "Sender": "ж7 компани",
    "Receiver": "oldCompany",
    "city": "Шымкент",
    "addressString": "Шымкент, микрорайон Восток, 5, ",
    "addressComments": ""
  },
  {
    "key": 67317664,
    "id": 67317764,
    "createDate": "2024-08-29T17:46:30.296478",
    "Sender": "ж7 компани",
    "Receiver": "mukhaDzhon",
    "city": "Шымкент",
    "addressString": "Шымкент, жилмассив Нурсат, 35, 1",
    "addressComments": ""
  },
  {
    "key": 51318287,
    "id": 51348287,
    "createDate": "2024-08-29T17:44:41.844523",
    "Sender": "newCompany inc",
    "Receiver": "ж7 компани",
    "city": "Шымкент",
    "addressString": "Шымкент, Адырбекова, 2Б, 1",
    "addressComments": ""
  },
  {
    "key": 76135935,
    "id": 76130935,
    "createDate": "2024-08-29T16:39:58.326452",
    "Sender": "ж7 компани",
    "Receiver": "mukhaDzhon",
    "city": "Шымкент",
    "addressString": "Шымкент, жилмассив Нурсат, 35, 1",
    "addressComments": ""
  },
  {
    "key": 16444711,
    "id": 16444911,
    "createDate": "2024-08-29T15:47:08.781152",
    "Sender": "Google3",
    "Receiver": "ж7 компани",
    "city": "Шымкент",
    "addressString": "Шымкент, Адырбекова, 2Б, 1",
    "addressComments": ""
  },
  {
    "key": 38891622,
    "id": 38891122,
    "createDate": "2024-08-29T15:34:52.128519",
    "Sender": "ж7 компани",
    "Receiver": "vasya",
    "city": "Шымкент",
    "addressString": "Шымкент, Фариды Шариповой, 100, ",
    "addressComments": ""
  },

]
