import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Button, Typography } from 'antd';
import { Alert } from 'react-bootstrap';
import { Funnel, FunnelFill, XSquare } from 'react-bootstrap-icons';

import { OrderSearchFilter } from '@/common/models/order';
import { OrderApiPageResponse } from '@/common/models/api/v0/order.dto';
import OrderService from '@/common/api/OrderService';
import FilterBar from './components/FilterBar';
import OrdersView from './components/OrdersView';
import SearchBar from './components/SearchBar';
import useAuth from '@/common/hooks/useAuth';
import usePage from '@/common/hooks/usePage';
import useDebounce from '@/common/hooks/useDebounce';
import { buildFetchOrdersQuery } from '@/common/utils/utils';
import OrderItemSort from '@/pages/order/components/OrderItemSort';
import useOrderSearch from '@/common/hooks/useOrderSearch';
import OrdersTabs from '@/pages/order/components/OrdersTabs';
import SelectBar from '@/pages/order/components/SelectBar';
import OrderImportWrapper from '@/pages/order/components/OrderImportWrapper';
import { useTranslation } from 'react-i18next';
import OrderExportXlsx from '@/pages/order/components/OrderExportXLSX';
import { Roles } from '@/common/constants/roles';
import { showAlert } from '@/common/utils/utilTypes';
import useImport from '@/common/hooks/useImport';

/*
 * TODO: The page has an excessive render issues. Refactoring and decomposition needed.
*/
const OrderViewPage = () => {
    const {user, hasAnyRole} = useAuth()
    const {setImportForm} = useImport()
    const {page, nextPage, previousPage, currentPage, resetPage, lastPage} = usePage()
    const [showAlert, setShowAlert] = useState<showAlert>({
        show: false,
        message: "",
        isError: false
    })
    const {t} = useTranslation()

    const { persistedFilterQuery, persistFilterQuery, selectedOrders, setSelectedOrders } = useOrderSearch()
    const [filterQuery, setFilterQuery] = useState<OrderSearchFilter | {}>(persistedFilterQuery)

    const [isFilterActive, setIsFilterActive] = useState<boolean>(false)

    const [searchType, setSearchType] = useState<string>('orderItemId')
    const [searchQuery, setSearchQuery] = useState<string>('')

    const filter = buildFetchOrdersQuery(searchQuery, searchType, filterQuery, currentPage)
    const {data, isFetching, error, refetch} = useQuery<OrderApiPageResponse>(['orders', currentPage, filterQuery],
        () => OrderService.getAll(filter))

    const [showImportModal, setShowImportModal] = useState(false)
    const refetchWithDebounce = useDebounce(refetch, 800)
    const resetPageWithDebounce = useDebounce(resetPage, 200)

    const handleSearchOptionChange = async (option: string) => {
        setSearchType(() => option)
    }

    const handleSearchInputChange = async (value: string) => {
        setSearchQuery(() => value)
        await resetPageWithDebounce()
        refetchWithDebounce()
    }

    const handleAnyChange = () => {
        refetch()
    }

    const handleShowAlert = (message, isError = true) => {
        setShowAlert({
            show: true,
            message: message,
            isError: isError
        })
    }

    const handleCloseAlert = () => {
        setShowAlert({
            show: false,
            message: "",
            isError: false
        })
    }

    useEffect(() => {
        if(showAlert.show){
            setTimeout(() => {
                    setShowAlert({
                        show: false,
                        message: "",
                        isError: false
                    })
            }, 4000)
        }
    }, [showAlert])

    useEffect(() => {
        setSelectedOrders(selectedOrders.map(selectedOrder => {
            return data?.orders?.find(refreshedOrder => refreshedOrder?.id === selectedOrder?.id) ?? selectedOrder
        }))
    }, [data, selectedOrders, setSelectedOrders])

    return (

            <div className="pt-3 position-relative">
                {/* Header */}
                <OrderImportWrapper showImportModal={showImportModal} setShowImportModal={setShowImportModal}/>
                <div className={"d-flex justify-content-between align-items-center"}>
                    <p className="heading-2 my-0">Заказы</p>
                </div>
                {showAlert.show && (
                  <Alert variant={showAlert.isError ? "danger" : "success"} className="d-flex align-items-center m-0 position-absolute" style={{padding: "0.5rem", right: 0, top: '1rem'}} >
                      <Alert.Heading className={"btn-text m-0"}>{showAlert?.message}</Alert.Heading>
                      <div style={{paddingLeft: "1rem", cursor: "pointer"}}
                           onClick={() => handleCloseAlert()}
                      >
                          <XSquare style={{width: "20px", height: "20px"}}/>
                      </div>
                  </Alert>
                )}

                {/* Parameters */}
                <div className="border border-rounded-1 my-1 p-3">
                    <div className="d-flex">
                        <SearchBar
                            role={user.roles[0]}
                            className="flex-grow-1"
                            onSelect={handleSearchOptionChange}
                            onChange={handleSearchInputChange}
                        />
                        <div className="px-1"></div>
                        <Button
                            className="d-flex border align-items-center border-rounded-1"
                            variant="outline-light"
                            size="sm"
                            onClick={() => setIsFilterActive(!isFilterActive)}
                        >
                            {isFilterActive ? (
                                <FunnelFill color="dark"/>
                            ) : (
                                <Funnel color="dark"/>
                            )}
                        </Button>
                    </div>
                    {isFilterActive && (
                        <div className="mt-1">
                            <div className="py-2">
                                <span className="heading-font">Фильтры</span>
                            </div>
                            <FilterBar
                                onChange={(option) => {
                                    setFilterQuery({...option})
                                    persistFilterQuery({...option})
                                    resetPage()
                                }}
                            />
                        </div>
                    )}
                </div>

                {/* Order list */}
                <div>
                    <div className={"mx-3 mt-2 mb-3 d-flex justify-content-between"}>
                        <OrdersTabs
                            key={persistedFilterQuery?.deliveryStatuses}
                            onChange={(option) => {
                                setFilterQuery({...option})
                                persistFilterQuery({...option})
                                resetPage()
                            }}
                        />
                        <div className={'d-flex'}>
                            <OrderExportXlsx key={filter} filter={filter} isClient={user.roles.includes(Roles.Client)} setShowAlert={setShowAlert}/>
                            {
                                (hasAnyRole([Roles.Manager, Roles.Admin])) && (
                                <Button
                                  onClick={() => {
                                      setShowImportModal(true)
                                      setImportForm({
                                          step: 0,
                                          type: "",
                                          fileName: "",
                                          fileSize: '',
                                          orders: [],
                                          brokenOrders: [],
                                          initialOrders: []
                                      })
                                  }}
                                  size={'large'}
                                  type={'primary'}
                                  style={{padding: '0 1rem', marginLeft: '1rem'}}
                                >
                                    <Typography.Text
                                      strong
                                      style={{ color: 'white', fontSize: '0.8rem', margin: 0}}
                                    >{t('ordersPage.buttons.importOrders')}</Typography.Text>
                                    <img className='edit-img' src='/assets/icons/import/clipboard-import.svg' alt='clipboard-import' />
                                </Button>
                              )
                            }
                        </div>
                    </div>
                    <div className={`d-flex justify-content-start align-items-center`}>
                        <OrderItemSort/>
                    </div>

                    <div style={{overflowY: 'auto', height: isFilterActive ? '52dvh' : '63dvh'}}>
                        <OrdersView
                            role={user.roles[0]}
                            data={data ? data.orders : []}
                            isLoading={isFetching}
                            error={error}
                            onAnyChange={handleAnyChange}
                        />
                    </div>
                    <SelectBar
                        data={data}
                        controlPage={{page, nextPage, previousPage, currentPage, resetPage, lastPage}}
                        showAlert={handleShowAlert}
                        onEdit={refetch}
                    />

                </div>
            </div>
    )
}

export default OrderViewPage
