import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Button, Modal, Typography, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import ImportTypeSelect from '@/pages/order-import/components/ImportTypeSelect';
import { getParsedOrders, hasEmptyFields } from '@/common/utils/parsers';
import { importTypesOptions, TypeExtensionMap } from '@/pages/order-import/importTypesOptions';
import useImport from '@/common/hooks/useImport';
import { CityApiPageResponse } from '@/common/models/api/v0/address.dto';


const {Title, Paragraph, Text} = Typography

const { Dragger } = Upload;

const OrderImportFileModal: FC<OrderImportFileModalProps> = observer(({cities, handleNextStep, handleBackStep, handleClose}) => {
  const navigate = useNavigate()
  const {importForm, setImportForm} = useImport();
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const props = {
    name: 'file',
    multiple: false,
    accept: TypeExtensionMap[importForm?.type]?.label,
    beforeUpload: () => {
      // Prevent automatic upload
      return false;
    },
    async onChange(info) {
      const file = info.fileList.filter(file => file.uid === info.file.uid)[0].originFileObj;

      if (file?.type !== TypeExtensionMap[importForm?.type]?.value) {
        setError(t('ordersImportPage.forms.upload.error.format'));
        return;
      }
      try {
        // Create a promise for FileReader
        const fileContent = await new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = (e) => {
            resolve(e.target.result);
          };

          reader.onerror = (e) => {
            reject(e);
          };

          reader.readAsArrayBuffer(file); // Adjust depending on the file type you're reading
        });

        setIsLoading(true);
        const parsingFile = importForm?.type === "HH" ? fileContent : file
        const readyTableOrders = await getParsedOrders(parsingFile, cities, importForm?.type);
        setImportForm({
          ...importForm,
          orders: readyTableOrders,
          fileName: file?.name,
          fileSize: (file?.size / 1000).toString() + ' кб',
          brokenOrders: importForm?.orders?.filter(order => hasEmptyFields(order) === true),
          initialOrders: readyTableOrders?.map(orderItem => {
            return {
              key: orderItem?.id,
              id: orderItem?.id,
              createDate: orderItem?.createDate,
              Sender: orderItem?.deliverySender?.company?.name,
              Receiver: orderItem?.deliveryReceiver?.company?.name,
              city: cities.find(city => city?.id === orderItem?.deliveryReceiver?.address?.cityId)?.name || "",
              addressString: orderItem?.deliveryReceiver?.address?.addressString || "",
              addressFromFile: orderItem?.addressFromFile || "",
              addressComments: orderItem?.deliveryReceiver?.address?.addressComment || ""
            };
          })
        })
        setIsLoading(false);
        setError(null);
      } catch (e) {
        console.log(e);
        setError(e);
      }
    },
  };
  const { t } = useTranslation();

  const handleClearFile = () => {
    setImportForm({
      ...importForm,
      fileName: '',
      fileSize: '',
      orders: [],
      brokenOrders: [],
      initialOrders: []
    })
    setError(null)
  }

  return (
    <Modal
      open={importForm?.step === 1}
      width={600}
      onCancel={handleClose}
      className={"import-second-step"}
      title={<Title level={3}>{t('ordersImportPage.forms.title')}</Title>}
      footer={
        <div id={"second-step-footer"}>
          <Button
            loading={isLoading}
            disabled={importForm?.fileName === "" || error !== null}
            className="footer-button"
            type={"primary"}
            onClick={() => navigate('/orders-import')}
          >{t('ordersImportPage.forms.load')}</Button>
          <Button
            className="footer-button"
            color={'danger'}
            variant={'solid'}
            onClick={() => {
              handleClearFile()
              handleBackStep()
            }}
          >{t('ordersImportPage.forms.back')}</Button>
        </div>
      }
    >
      <Title className={'second-step-title'} level={5}>{t("ordersImportPage.forms.type")}</Title>
      <ImportTypeSelect
        className={'import-dropdown'}
        disabled={true}
        style={{width: "45%"}}
        placeHolder={<Text>{importTypesOptions.find(option => option.value === importForm?.type)?.label || t("ordersImportPage.forms.typeDropdown")}</Text>}
      />
      {(importForm?.fileName) && (
        <div id='second-step-body'>
          <img className='file-img' src={`/assets/icons/import/${importForm?.type === 'HH' ? 'excel.svg' : 'pdf.svg'}`} alt='icon' />
          <div className='file-body'>
            <div className='import-file-name'>{importForm?.fileName}</div>
            <div className='import-file-size'>{importForm?.fileSize}</div>
          </div>
          <img onClick={handleClearFile} className='import-file-status' src={`/assets/icons/import/${error === null ? 'done.svg' : 'error.svg'}`} alt='icon' />
        </div>
      )}
      {(!importForm?.fileName) && (
        <Dragger
          className='import-dragger'
          showUploadList={false}
          {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <div className="dragger-text">{t('ordersImportPage.forms.upload.body.drag')} <Text className="dragger-text" style={{color: 'var(--bs-primary)'}}>{t('ordersImportPage.forms.upload.body.click')}</Text></div>
          <p className="ant-upload-hint dragger-hint">{t('ordersImportPage.forms.upload.body.formats')} {TypeExtensionMap[importForm?.type]?.label}</p>
        </Dragger>
      )}
      {error !== null && typeof error === "string" && (
        <div className='import-error'>
          <div className='import-error-body'>
            {error?.split('.').map((text, index) => (
              <Paragraph className={'error-text'} key={index} style={{fontWeight: `${index === 0 ? 'bold' : 'normal'}`}} type={'danger'}>{text} <Text type={'danger'} style={{fontWeight: 'bold'}}>{index > 0 ? TypeExtensionMap[importForm?.type]?.label : ""}</Text></Paragraph>
            ))}
          </div>
          <img onClick={handleClearFile} className='import-file-status' src={`/assets/icons/import/error.svg`} alt='icon' />
        </div>
      )}
    </Modal>
  );
})

interface OrderImportFileModalProps {
  cities: CityApiPageResponse,
  handleNextStep: () => void,
  handleBackStep: () => void,
  handleClose: () => void
}

export default OrderImportFileModal;