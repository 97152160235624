import React, { FC, SetStateAction, useState } from 'react';
import { Button, Modal, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


import useImport from '@/common/hooks/useImport';
import { convertToTimeZone, formatToTimeStamp } from '@/common/utils/utils';
import { Formats } from '@/common/constants/dateFormat';
import { OrderCreateData } from '@/common/models/order';


interface OrderImportConfirmModalProps {
  title: string, 
  handleClose: () => void, 
  onSubmit: () => SetStateAction<any>,
  handleNextStep: () => void
}

const OrderImportConfirmModal: FC<OrderImportConfirmModalProps> = observer(({title, handleClose, onSubmit, handleNextStep}) => {
  const { importForm, setImportForm} = useImport()
  const navigate = useNavigate()
  const {t} = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [createResult, setCreateResult] = useState<OrderCreateData>({})
  const finishCreation = () => {
    setImportForm({
      step: 0,
      type: "",
      fileName: "",
      fileSize: '',
      orders: [],
      brokenOrders: []
    })

    navigate('/orders')
  }

  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      handleNextStep()
      setCreateResult(await onSubmit())
    } catch (e) {
      console.log(e);
      setError(e)
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <>
      {
        importForm?.step === 1 && (
          <Modal
            open={true}
            className={"import-confirm-modal"}
            title={<Typography.Title level={3}>{title}</Typography.Title>}
            footer={<div className='d-flex justify-content-around'>
              <Button onClick={handleSubmit} style={{backgroundColor: '#ccfcdc', border: '1px solid #5DC983', fontWeight: 600, fontSize: '1rem', color: '#5DC983', padding: '1rem 2rem'}}>{t('ordersImportPage.table.ok')}</Button>
              <Button onClick={handleClose} style={{backgroundColor: '#ffd2d5', border: '1px solid #EB5769', fontWeight: 600, fontSize: '1rem', color: '#EB5769', padding: '1rem 2rem'}}>{t('ordersImportPage.table.cancel')}</Button>
            </div>}
            onCancel={handleClose}
          />
        )
      }
      {
        importForm?.step === 2 && (
          <Modal
            open={importForm?.step === 2}
            onClose={handleClose}
            footer={<div className='d-flex justify-content-start'>
              <Button onClick={finishCreation} style={{padding: '1rem 2rem'}} type={'primary'} loading={isLoading}>OK</Button>
            </div>}
            title={<Typography.Title level={3}>{t('ordersImportPage.result.title')}</Typography.Title>}
          >
            {isLoading && (<div>
              {t('ordersImportPage.result.loading', {orderCount: importForm?.orders?.length})}
            </div>)}
            {!isLoading && (
              <div>
                <Typography.Paragraph>
                  <Typography.Text className={'import-cell'} strong>{t('ordersImportPage.result.fileName')}</Typography.Text>
                  <Typography.Text className={'import-cell'}> {importForm?.fileName}</Typography.Text>
                </Typography.Paragraph>
                <Typography.Paragraph>
                  <Typography.Text className={'import-cell'} strong>{t('ordersImportPage.result.fileCount')}</Typography.Text>
                  <Typography.Text className={'import-cell'}> {createResult?.orderItems?.length}</Typography.Text>
                </Typography.Paragraph>
                <Typography.Paragraph>
                  <Typography.Text className={'import-cell'} strong>{t('ordersImportPage.result.uploadDateTime')}</Typography.Text>
                  <Typography.Text className={'import-cell'}> {convertToTimeZone(formatToTimeStamp(Date.now()), false, Formats.DATE_DMMMY_TIME, true)}</Typography.Text>
                </Typography.Paragraph>
              </div>
            )}
            {error && (
              <Typography.Text type={'danger'}>{error}</Typography.Text>
            )}
          </Modal>
        )
      }

    </>

  );
})

export default OrderImportConfirmModal;