import React, { CSSProperties, FC, ReactNode } from 'react';
import { Select, Typography } from 'antd';


import { SelectOption } from '@/common/models/util';
import { importTypesOptions } from '@/pages/order-import/importTypesOptions';
import useImport from '@/common/hooks/useImport';
import { observer } from 'mobx-react-lite';

const { Text } = Typography;


const importTypeOptions: SelectOption[] = importTypesOptions.map(type => ({
  value: type?.value,
  label: <Text style={{ fontSize: '0.75rem' }}>{type?.label}</Text>,
}));


const ImportTypeSelect: FC<ImportTypeSelectProps> = observer(({ disabled = false, className = '', style= {}, placeHolder}) => {
  const {importForm, setImportForm} = useImport();
  function handleSelectType (option) {
    setImportForm({
      ...importForm,
      type: option
    })
  }
  return (
    <Select
      className={className}
      disabled={disabled}
      style={style}
      placeholder={placeHolder}
      options={importTypeOptions}
      onSelect={handleSelectType}
    />
  );
})

interface ImportTypeSelectProps {
  className?: string,
  disabled?: boolean,
  style?: CSSProperties,
  placeHolder: ReactNode,
}

export default ImportTypeSelect;