import * as XLSX from 'xlsx';
import { pdfjs } from 'react-pdf';


import { importTypes, TypeSenderMap } from '@/pages/order-import/importTypesOptions';
import { AddressService } from '@/common/api/AddressService';
import { ChangedAddress, ImportTableOrder, OrderItemCreateData } from '@/common/models/order';
import OrderService from '@/common/api/OrderService';
import { TableOrder } from '@/pages/order-import/components/OrderImportTable';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;



export function parseAlsekoPdf(arrayText: string[]) {
  let result = {};
  let headerPassedFlag = false;

  let curr = {
    addr : '',
    ksk :'',
    kvs :'',
    total: 0,

  }


  arrayText.forEach((string, i) => {
    if (string.includes('Название улицы, дом, кв')) {
      headerPassedFlag = true;
    }
    if (headerPassedFlag) {
      if (curr?.ksk) {
        if (!result[curr?.ksk]) {
          result[curr?.ksk] = {
            total: curr?.total,
            addresses: [
              {
                address: curr?.addr,
                apartments: getApartmentsOptimized(curr?.kvs.split(',').map(slice => {
                  if (slice !== '' && slice !== ' ') {
                    return slice.trim();
                  }
                  return null; // Explicitly return null or some other value if the condition is not met
                }).filter(Boolean)),
                apartmentsTotal: curr?.total
              },
            ],
          };
        }
        else {
          result[curr?.ksk] = {
            ...result[curr?.ksk],
            total: curr?.total + result[curr?.ksk].total,
            addresses: [
              ...result[curr?.ksk]?.addresses,
              {
                address: curr?.addr,
                apartments: getApartmentsOptimized(curr?.kvs.split(',').map(slice => {
                  if (slice !== '' && slice !== ' ') {
                    return slice.trim();
                  }
                  return null; // Explicitly return null or some other value if the condition is not met
                }).filter(Boolean)),
                apartmentsTotal: curr?.total
              },
            ],
          };
        }
        curr.kvs = '';
        curr.addr = '';
        curr.ksk = '';
      }
      if (!curr?.addr && (string.includes('г.') || string.includes('ул.') || string.includes('м-он') || string.includes('пр.') || string.includes('д.'))) {

        curr.addr = string.replace(arrayText[i].trim().split(' ')[0], '').trim();
        if (!curr?.addr.includes('г.Алматы')) {
          curr.addr = 'г.Алматы ' + curr?.addr;
        }
        curr.addr = curr?.addr.trim();
        curr.total = Number(arrayText[i-1].trim().split(' ')[0]);

      } else if (curr?.addr) {
        if (!string.includes('КСК')) {
          curr.kvs += string.replace('\n', '');

        } else {
          curr.ksk = string.trim() + ' ' + arrayText[i + 1];
        }
      }
    }
  })
  return result;
}
function getApartmentsOptimized(apartments) {
  let nums = [];
  let strs = [];

  // Split the string into individual apartment numbers
  apartments.forEach(apt => {
    // Check if the apartment is a pure number
    if (/^\d+$/.test(apt)) {
      nums.push(parseInt(apt, 10));
    } else {
      strs.push(apt);
    }
  });

  // Call the consecutiveRanges function to group numeric apartments
  let result = consecutiveRanges(nums);

  // Append the strings (non-numeric apartments) to the result
  result.push(...strs);

  // Join the result into a single string
  return result.join(', ');
}

// Helper function to group consecutive numbers
function consecutiveRanges(a) {
  let list = [];
  let length = 1;

  if (a.length === 0) {
    return list;
  }

  // Sort numbers to ensure correct range formation
  a.sort((x, y) => x - y);

  // Iterate through the numbers and find ranges
  for (let i = 1; i <= a.length; i++) {
    // If there's a gap between numbers or we've reached the end of the array
    if (i === a.length || a[i] - a[i - 1] !== 1) {
      // If it's a single number, add it to the list
      if (length === 1) {
        list.push(String(a[i - length]));
      }
      // If it's a range, add it as "start-end"
      else {
        list.push(`${a[i - length]}-${a[i - 1]}`);
      }
      length = 1;
    } else {
      length++;
    }
  }
  return list;
}

export function getPluralApparments(num: number) {
  const pluralRules = new Intl.PluralRules("ru");
  const cases = {
    one: "письмо",
    few: "письма",
    many: "писем",
    other: "писем",
  };

  const form = pluralRules.select(num);
  return `${num} ${cases[form]}`;
}

export const extractTextFromPDF = async (file) => {
  const arrayBuffer = await file.arrayBuffer(); // Convert file to array buffer
  const pdf = await pdfjs.getDocument({ data: arrayBuffer }).promise; // Load the PDF document
  let lines = [];

  for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
    const page = await pdf.getPage(pageNumber); // Get each page
    const content = await page.getTextContent(); // Extract the text content of the page

    // Process content.items to extract text line by line
    content.items.forEach((item) => {
      if (item.str.trim()) {
        lines.push(item.str.trim()); // Add each text string to the lines array, trimming extra spaces
      }
    });
  }

  return lines; // Return an array of lines with extracted text
};


export function parseHHExcel(file) {
  const result = {};

  const data = new Uint8Array(file);
  const workbook = XLSX.read(data, { type: 'array' });

  const sheetName = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[sheetName];
  const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  jsonData.forEach(order => {
    if (!result[order[2]]) {
      result[order[2]] = {
        total: 1,
        addresses: [
          {
            address: order[5],
            apartments: order[6] || '',
            bin: order[1] || undefined,
            apartmentsTotal: 1
          },
        ],
      };
    } else {
      result[order[2]] = {
        ...result[order[2]],
        total: result[order[2]]?.total + 1,
        addresses: [
          ...result[order[2]]?.addresses,
          {
            address: order[5],
            apartments: order[6] || '',
            bin: order[1] || undefined,
            apartmentsTotal: 1
          },
        ],
      };
    }
  });
  return result;
}

// Function to check if a row has any empty fields
export const hasEmptyFields = (record: ImportTableOrder) => {
  return (
    !record.Sender ||
    !record.createDate ||
    !record.Receiver ||
    !record.addressString ||
    !record.city
  );
};


export const getParsedOrders = async (file, cities, importType: importTypes) => {
  let parseResult = {};
  if (!importType)
    return [];
  // If the import type is 'Alseko', extract text using react-pdf
  if (importType === 'Alseko') {
    const extractedText = await extractTextFromPDF(file);
    parseResult = parseAlsekoPdf(extractedText);

  } else if (importType === 'HH') {
    parseResult = parseHHExcel(file);
  }
  let numberedAddresses = [];
  let counter = 1;
  Object.keys(parseResult).forEach(company => {
    parseResult[company]?.addresses?.forEach(address => {
      numberedAddresses.push({
        ...address,
        name: company,
        id: counter++,
        total: address?.apartmentsTotal
      });
    });
  });
  numberedAddresses = numberedAddresses?.filter(address => address?.name !== "undefined" && address?.address)

  let clearAddresses = (await AddressService.getClearAddress({
    addresses: numberedAddresses
  }))?.data
  return numberedAddresses.map(numberedAddress => {
    const clearAddress = clearAddresses?.find(clearAddress => numberedAddress?.id === clearAddress?.id)
    return {
      id: numberedAddress?.id,
      createDate: Date.now(),
      apartmentsTotal: numberedAddress?.total,
      addressFromFile: clearAddress?.queryAddress,
      status: 'NEW',
      deliveryType: 'STANDARD',
      parcelType: 'ENVELOPE',
      comments: `${numberedAddress?.apartments} ${clearAddress?.addressComments || "" }`,
      deliveryReceiver: {
        address: {
          ...clearAddress,
          city: cities?.find(city => city?.id === clearAddress?.cityId) || cities?.find(city => city?.name === clearAddress?.cityId),
          addressString: `${clearAddress?.street || '' } ${clearAddress?.building || ''}`,
          addressComment: `${numberedAddress?.apartments} ${clearAddress?.addressComments || "" }`,
        },
        phone: clearAddress?.contactPhone,
        contactName: "",
        isCompany: true,
        company: {
          name: numberedAddress?.name,
          bin: numberedAddress?.bin || "",
          streetName: clearAddress?.street,
          apartment: clearAddress?.building,
          houseNumber: numberedAddress?.apartments,
          contactPhone: clearAddress?.phone || "",
          point: clearAddress?.point,
          addressComments: `${numberedAddress?.apartments} ${clearAddress?.addressComments || "" }`,
          city: {
            id: clearAddress?.cityId
          },
          address: clearAddress
        }
      },
      deliverySender: TypeSenderMap[importType]
    }
  })
};

export async function changeAddress(addressString, id, defaultOrders, setDefaultOrders, cities) {
  let clearChangedAddress = (await AddressService.getClearAddress({
    addresses: [{
      id: id,
      address: addressString
    }]
  }))?.data[0]
  setDefaultOrders(defaultOrders.map(defaultOrder => {
    if(defaultOrder?.id === id) {
      return {
        ...defaultOrder,
        deliveryReceiver: {
          ...defaultOrder?.deliveryReceiver,
          address: {
            ...defaultOrder?.deliveryReceiver?.address,
            ...clearChangedAddress,
            city: cities?.find(city => city?.id === clearChangedAddress?.cityId),
            addressString: `${clearChangedAddress?.street || ""} ${clearChangedAddress?.building || ""}`,
            addressComment: clearChangedAddress?.addressComments || "",
          },
          phone: clearChangedAddress?.phone || defaultOrder?.phone,
          contactName: clearChangedAddress?.contactPhone || defaultOrder?.deliveryReceiver?.contactName || "",
          isCompany: true,
          company: {
            ...defaultOrder?.deliveryReceiver?.company,
            bin: defaultOrder?.bin || "",
            streetName: clearChangedAddress?.street,
            apartment: clearChangedAddress?.building,
            contactPhone: clearChangedAddress?.contactPhone || defaultOrder?.deliveryReceiver?.company?.phone || "",
            point: clearChangedAddress?.point,
            addressComments: clearChangedAddress?.addressComments || defaultOrder?.deliveryReceiver?.company?.addressComments,
            city: cities?.find(city => city?.id === clearChangedAddress?.cityId),
            address: {
              ...defaultOrder?.deliveryReceiver?.address,
              ...clearChangedAddress,
              city: cities?.find(city => city?.id === clearChangedAddress?.cityId),
              addressString: `${clearChangedAddress?.street || ""} ${clearChangedAddress?.building || ""}`,
              addressComment: clearChangedAddress?.addressComments || "",
            }
          }
        }
      }
    }

    return defaultOrder
  }))
}

export async function getReadyToImportOrders(importType, tableOrders: TableOrder[], defaultOrders, cities) {
  const changedAddresses: ChangedAddress[] = [];
  const readyForCreateOrders: OrderItemCreateData[] = defaultOrders.map(defaultOrder => {
    const tableOrder: TableOrder = tableOrders?.find((tableOrder): TableOrder => defaultOrder?.id === tableOrder?.id)
    if (tableOrder.isFixed) changedAddresses.push({
      initValue:tableOrder?.addressComments || '',
      changedValue: `${cities?.find(city => city?.name === tableOrder?.city).name} ${defaultOrder?.deliveryReceiver?.address?.street} ${defaultOrder?.deliveryReceiver?.address?.building}`
    })
    return {
      deliveryType: 'STANDARD',
      ...(importType === "Alseko" ? { deliveryProcessType: "ALSECO" } : {}),
      parcelType: 'ENVELOPE',
      itemPartsCount: tableOrder?.total,
      deliveryReceiver: {
        isCompany: true,
        address: {
          city: cities?.find(city => city?.name === tableOrder?.city),
          street: defaultOrder?.deliveryReceiver?.address?.street,
          apartment: defaultOrder?.deliveryReceiver?.address?.apartment,
          building: defaultOrder?.deliveryReceiver?.address?.building,
          point: defaultOrder?.deliveryReceiver?.address?.point,
          comment: tableOrder?.addressComments || "",
        },
        phone: tableOrder?.phone.replace(/\D/g, '') || "",
        contactName: tableOrder?.Receiver,
        company: {
          name: tableOrder?.Receiver,
          bin: defaultOrder?.deliveryReceiver?.company?.bin || "",
        }
      },
      comments: tableOrder?.addressComments || "",
    }
  });
  return await OrderService.create({
    deliverySender: TypeSenderMap[importType],
    orderItems: readyForCreateOrders,
    changedAddresses
  })
}