import { OrderSearchQuery, OrderItem } from "@/common/models/order"
import { OrderApiPageResponse } from "@/common/models/api/v0/order.dto"
import requests from "./tgAgent"
import { AttachmentResponse } from "@/common/models/api/v0/attachment.dto"

/**
 * TODO: Temporary solution to avoid pagination. Should be removed on further releases.
 */
const MAX_PAGE_SIZE = 10_000

const OrderService = {
    getAll: async (query: OrderSearchQuery): Promise<OrderApiPageResponse> => {
        query.size = MAX_PAGE_SIZE
        return requests.get('/order-items', query)
            .then((response) => {
                let currentPage = response.number + 1
                let currentElements: number

                if (currentPage > 1) {
                    currentElements = response.size * (currentPage - 1) + response.numberOfElements 
                } else {
                    currentElements = response.numberOfElements
                }

                return {
                    orders: response.content,
                    currentPage: currentPage,
                    isLast: response.last,
                    total: response.totalElements,
                    left: currentElements,
                    totalPages: response.totalPages,
                }
            })
    },
    getById: async (id: number): Promise<OrderItem> => {
        return requests.get(`/order-items/${id}`)
    },
    changeStatus: async (orderItemId: number, event: string): Promise<string> => {
        return requests.post(`/order-items/${orderItemId}/change-status?event=${event}`)
    },
    batchChangeStatus: async (orderItemIds: number[], event: string): Promise<string> => {
        return requests.post(`/order-items/change-statuses?event=${event}`, {
            orderItemIds: orderItemIds
        })
    },
    saveAllFiles: async (orderItemId: number, files: string[]): Promise<AttachmentResponse[]> => {
        return requests.post(`/order-items/${orderItemId}/attachments`, { files })
    },
    getAllFiles: async (orderItemId: number): Promise<AttachmentResponse[]> => {
        return requests.get(`/order-items/${orderItemId}/attachments`)
    },
    deleteFile: async (orderItemId: number, fileId: number): Promise<void> => {
        return requests.delete(`/order-items/${orderItemId}/attachments/${fileId}`)
    }
}

export default OrderService;