import React, { InputHTMLAttributes, memo, useEffect, useState } from 'react'
import { InputMask, Replacement } from '@react-input/mask'

import './FormikInput.css'
import { checkIfEmpty, formatNumberMasked } from '@/common/utils/utils'


const PhoneInputWithoutFormik = ({
                            label, id, name, className, initialValue, required = false, onMaskChange, ...rest
                          }: InputHTMLAttributes<HTMLInputElement> & InputPhoneMaskedProps) => {
  const phoneMask = { mask: '+_ (___) ___-__-__', replacement: { _: /\d/ } }
  const [displayValue, setDisplayValue] = useState<string>(initialValue || '')

  const normalize = (value: string) => {
    if (!value.startsWith('7') && value.length === 11) {
      return '7' + value.substring(1)
    }
    return value
  }
  useEffect(() => {
    const normalizedInitialValue = normalize(displayValue)
    if (displayValue !== normalizedInitialValue) {
      setDisplayValue(normalizedInitialValue)
      onMaskChange(normalizedInitialValue.replace(/\D/g, ''))
    }
  }, [displayValue, onMaskChange])

  const handleMaskChange = (value: string) => {
    const normalizedValue = normalize(value)
    setDisplayValue(normalizedValue)
    onMaskChange(normalizedValue)
  }

  return (
    <div className={`d-flex flex-column ${checkIfEmpty(className)}`}>
      <label htmlFor={id} className="formik-label">
        {label}
        {required && <span className="text-danger">*</span>}
      </label>
      <InputMaskWrapped
        id={id}
        name={name}
        className="formik-input"
        mask={phoneMask.mask}
        replacement={phoneMask.replacement}
        value={formatNumberMasked(displayValue)}
        onMaskChange={handleMaskChange}
        {...rest}
      />
    </div>
  )
}

const InputMaskWrapped = ({
                            replacement, mask, onMaskChange, ...rest
                          }: InputHTMLAttributes<HTMLInputElement> & InputMaskWrappedProps) => {
  return (
    <InputMask
      mask={mask}
      replacement={replacement}
      showMask={true}
      {...rest}
      onMask={(e) => onMaskChange(e.detail.input)}
    />
  )
}

interface InputPhoneMaskedProps {
  label: string,
  className?: string,
  initialValue?: string,
  required?: boolean
  onMaskChange: (value: string) => void,
}

interface InputMaskWrappedProps {
  mask: string
  replacement: Replacement,
  onMaskChange: (value: string) => void,
}

export default memo(PhoneInputWithoutFormik)
