import { notification, NotificationArgsProps } from "antd"

type NotificationPlacement = NotificationArgsProps['placement'];

type UseAlert = {
    sendError: (errorText: string, description?: string) => void,
}

const useAlert = (): UseAlert => {
    const defaultPlacement: NotificationPlacement = 'topRight' 

    const sendError = (message: string, description?: string) =>
        notification.error({
            message,
            description,
            placement: defaultPlacement,
        })

    return { sendError } as const
}

export default useAlert