export enum Formats {
    DATE_TIMESTAMP = "yyyy-MM-dd'T'HH:mm:ss",
    DATE_DMY_TIME = "dd/MM/yyyy - HH:mm",
    DATE_DMY = "dd-MM-yyyy",
    DATE_DMY_DOT = "dd.MM.yyyy",
    EXPORT_TIMESTAMP = "dd.MM.yyyy_HH-mm",
	  DATE_YMD = "yyyy-MM-dd",
    DATE_DMMMY_TIME = "dd MMM, yyyy, HH:mm"
}

export const possibleDateFormats = [
    'yyyy-MM-dd',
    'dd-MM-yyyy',
    'MM/dd/yyyy',
    'yyyy/MM/dd',
    'dd/MM/yyyy',
    'yyyyMMdd',
    'ddMMyyyy'
]
