import { useMemo } from "react"



const useSearch = <T>(data: T[], targetPath: string, searchValue: string) => {

    const getNestedProperty = (obj: any, path: string | null): any => {
        if (!path) {
            return obj
        }

        const args: string[] = path.split('.')

        if (args.length < 2) {
            return obj && obj[path]
        }

        return args.reduce((acc, key) => 
            acc && acc[key], obj)
    }

    const searchResult = useMemo(() => {
        if (!searchValue || searchValue === '') {
            return data
        }

        return data
            .filter(d => {
                const val: string | number = getNestedProperty(d, targetPath)
                const preparedVal = String(val).toLocaleLowerCase().replace(/[\s,]+/g, '')
                const preparedSearchVal = searchValue.toLocaleLowerCase().replace(/[\s,]+/g, '')
                return preparedVal.includes(preparedSearchVal)
            })
    }, [data, targetPath, searchValue])

    return { searchResult } as const
}

export default useSearch