import { FC } from "react"
import { useTranslation } from "react-i18next"

const Field: FC<FieldProps> = ({ header, value }) => {
    const { t } = useTranslation()

    return (
        <div>
            <span className="header-2">{header}:</span>
            {value ? (
                <span className="font-property">{value}</span>
            ) : (
                <span className="font-property text-muted">{t('pages.orderNotePrint.noData')}</span>
            )}
        </div>
    )
}

interface FieldProps {
    header: string,
    value?: string | number,
}

export default Field