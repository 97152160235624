import { FC } from "react"
import { useTranslation } from "react-i18next"

const OrderProperty: FC<OrderPropertyProps> = ({ text, value }) => {
    const { t } = useTranslation()

    return (
        <div>
            <span className="d-block heading-font">{text}</span>
            {value ? (
                <span>{value}</span>
            ) : (
                <span className="text-muted">{t('telegram.pages.orderAction.noData')}</span>
            )}
        </div>
    )
}

interface OrderPropertyProps {
    text: string,
    value: any,
}

export default OrderProperty