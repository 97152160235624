import React, { useEffect } from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useQuery } from 'react-query';


import './index.scss'
import OrderImportTable from '@/pages/order-import/components/OrderImportTable';
import useImport from '@/common/hooks/useImport';
import { CityService } from '@/common/api/CityService';

const {Title} = Typography

const OrderImportViewPage = observer(() => {
  const {t} = useTranslation()
  const {importForm} = useImport()
  const { data: citiesData, refetch } = useQuery(['getCitiesForImportPage'],
    () => CityService.getAll({ size: 100 }),
    {
      enabled: false,
    },
  );
  useEffect(() => {
    refetch()
  }, [refetch])

  return (
    <div className='orders-import'>
      {
        (importForm?.orders?.length - importForm?.brokenOrders?.length) > 0 && (
          <>
            <Title level={1} style={{marginTop: '1rem'}}>{t('ordersImportPage.title')}</Title>
            <div className={'d-flex align-items-start'}>
              <img style={{width: 32, marginRight: 8}} src={`/assets/icons/import/${importForm?.brokenOrders?.length > 0 ? 'error.svg' : 'done.svg'}`} alt='' />
              <div className={'d-flex flex-column'}>
                <Title
                  level={3}
                  style={{margin: '0'}}
                >{t('ordersImportPage.total', {
                  total: importForm?.orders?.length,
                  success: importForm?.orders?.length - importForm?.brokenOrders?.length
                })}</Title>
                {importForm?.brokenOrders?.length > 0 && (
                  <Title
                    level={3}
                    style={{margin: '0'}}
                  >{t('ordersImportPage.totalError')}</Title>
                )}
              </div>
            </div>
          </>
        )
      }

      <OrderImportTable citiesData={citiesData}/>
    </div>
  );
});

export default OrderImportViewPage;