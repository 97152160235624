import React from 'react'
import { useLocation } from 'react-router-dom'

import OrderActionCard from './components/OrderActionCard'

const TelegramOrderActionPage = () => {
    const location = useLocation()
    const order = JSON.parse(location.state?.order || '')

    return (
        <div>
            {order && (
                <OrderActionCard data={order} />
            )}
        </div>
    )
}

export default TelegramOrderActionPage