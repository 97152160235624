import React from 'react'
import { NavLink } from 'react-router-dom'

import '../Sidebar.scss'

const NavItem = ({ currentPath, toPath, icon, text, defaultChoice = false, show = true }: NavItemProps) => {

    if (!show) {
        return
    }

    return (
        <NavLink
            className={`w-100 text-decor-none border-rounded-1 p-1 ${(currentPath === toPath || defaultChoice) ? 'active text-light' : 'text-dark'}`}
            style={{
                backgroundColor: `${(currentPath === toPath || defaultChoice) ? 'var(--bs-primary)' : 'inherit'}`
        }}
            to={toPath}
        >
            <div className="d-flex align-items-center py-1">
                {icon}
                <span className="px-2 fw-medium">{text}</span>
            </div>
        </NavLink>
    )
}

interface NavItemProps {
    currentPath: string,
    toPath: string,
    icon: React.ReactNode,
    text: string,
    defaultChoice?: boolean,
    show?: boolean,
}

export default NavItem