import React, { useState } from 'react'
import { Row, Col, Alert, Spinner } from 'react-bootstrap'
import { Formik, Form } from 'formik'
import { observer } from 'mobx-react-lite'

import useOrderCreateForm from '@/common/hooks/useOrderCreateForm'
import Heading from '@/components/typography/Heading'
import DefaultButton from '@/components/buttons/DefaultButton'
import DeliveryPartyForm from '../forms/DeliveryPartyForm'
import OrderDetailsForm from '../forms/OrderDetailsForm'
import { OrderCreateData } from '@/common/models/order'
import { StepActionBottomBar } from '.'
import { initialValues } from '../forms/form-data'
import { validationSchema } from '../forms/validation'
import { useTranslation } from 'react-i18next';

export const GetOrderDetailsStep = observer(() => {
    const {t} = useTranslation()
    const form = useOrderCreateForm()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<any>()

    const handleSubmit = (values: OrderCreateData) => {
        setIsLoading(true)
        form.updateForm(values)
        if (form.isEditing) {
            form.updateOrderItem(values.orderItems[0], values.orderItems[0].id)
                .then((response) => {
                    setError(null)
                    form.updateForm(values)
                    form.setOrderCreated({ ...form.orderCreated, orderItems: [response] })
                })
                .then(() => form.goForward())
                .catch((e) => setError(e))
                .finally(() => setIsLoading(false))
        } else {
            form.createOrder(values)
                .then((response) => {
                    setError(null)
                    form.updateForm(values)
                    form.setOrderCreated(response)
                })
                .then(() => form.goForward())
                .catch((e) => setError(e))
                .finally(() => setIsLoading(false))
        }
    }

    const getInitialValues = () => {
        if (form.isEditing) {
            return form.orderCreated
        }

        if (!form.roleCompany) {
            return initialValues
        }

        const { roleCompany, roleDeliveryParty } = form

        /**
         * God forgive me!! (；′⌒`)
         */
        switch (form.role) {
            case 'Sender': {
                initialValues.deliverySender.company.name = roleCompany.name
                initialValues.deliverySender.company.bin = roleCompany.bin
                initialValues.deliverySender.address.street = roleCompany.streetName
                initialValues.deliverySender.address.building = roleCompany.houseNumber
                initialValues.deliverySender.address.apartment = roleCompany.apartment
                initialValues.deliverySender.address.comment = roleCompany.addressComments
                initialValues.deliverySender.address.city = roleCompany.city
                initialValues.orderItems[0].deliveryReceiver.address.city = roleCompany.city
                if (roleDeliveryParty) {
                    initialValues.deliverySender.phone = roleDeliveryParty.phone
                }
                initialValues.orderItems[0].deliveryReceiver.company.name = ''
                initialValues.orderItems[0].deliveryReceiver.company.bin = ''
                initialValues.orderItems[0].deliveryReceiver.address.street = ''
                initialValues.orderItems[0].deliveryReceiver.address.building = ''
                initialValues.orderItems[0].deliveryReceiver.address.apartment = ''
                initialValues.orderItems[0].deliveryReceiver.address.comment = ''
                initialValues.orderItems[0].deliveryReceiver.phone = ''
                break
            }
            case 'Receiver': {
                initialValues.orderItems[0].deliveryReceiver.company.name = roleCompany.name
                initialValues.orderItems[0].deliveryReceiver.company.bin = roleCompany.bin
                initialValues.orderItems[0].deliveryReceiver.address.street = roleCompany.streetName
                initialValues.orderItems[0].deliveryReceiver.address.building = roleCompany.houseNumber
                initialValues.orderItems[0].deliveryReceiver.address.apartment = roleCompany.apartment
                initialValues.orderItems[0].deliveryReceiver.address.comment = roleCompany.addressComments
                initialValues.orderItems[0].deliveryReceiver.address.city = roleCompany.city
                initialValues.orderItems[0].deliveryReceiver.address.point = roleCompany.point
                if (roleDeliveryParty) {
                    initialValues.orderItems[0].deliveryReceiver.phone = roleDeliveryParty.phone
                }
                initialValues.deliverySender.company.name = ''
                initialValues.deliverySender.company.bin = ''
                initialValues.deliverySender.address.street = ''
                initialValues.deliverySender.address.building = ''
                initialValues.deliverySender.address.apartment = ''
                initialValues.deliverySender.address.comment = ''
                initialValues.deliverySender.phone = ''
                break
            }
        }
        return initialValues
    }

    return (
        <div className="position-relative">
            <LoadingOverlap show={isLoading} />
            <Formik
                initialValues={getInitialValues()}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ setFieldValue, isValid }) => (

                    <Form key={'OrderCreate'}>
                        {error && (
                            <Alert className="border-rounded-1" variant="danger">
                                <span>{JSON.stringify(error.response?.body)}</span>
                            </Alert>
                        )}
                        <div className="d-flex">
                            <Heading size="sm">
                                {t('orderCreate.confirmForm.deliveryInfo')}
                            </Heading>
                            <StepActionBottomBar>
                                {!form.isEditing && (
                                    <DefaultButton variant="light" bordered onClick={form.goBack}>
                                        {t('orderCreate.confirmForm.back')}
                                    </DefaultButton>
                                )}
                                <DefaultButton
                                    disabled={!isValid}
                                    isLoading={isLoading}
                                    bordered
                                    submit
                                >
                                    {form.isEditing ? t('orderCreate.confirmForm.saveChanges') : t('orderCreate.confirmForm.create')}
                                </DefaultButton>
                            </StepActionBottomBar>
                        </div>
                        <Row>
                            <Col>
                                <div>
                                    <DeliveryPartyForm
                                        role="Sender"
                                        key="Sender"
                                        initialValues={getInitialValues()}
                                        disabled={isLoading || form.isEditing}
                                        setFieldValue={setFieldValue}
                                        highlightFor={form.role}
                                        type="deliverySender"
                                    />
                                </div>
                                <div className="mt-3">
                                    <Heading size="sm">
                                        {t('orderCreate.confirmForm.deliveryInfo')}
                                    </Heading>
                                    <div>
                                        <OrderDetailsForm
                                            initialValues={getInitialValues()}
                                            disabled={isLoading}
                                            setFieldValue={setFieldValue}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div>
                                    <DeliveryPartyForm
                                        role="Receiver"
                                        key="Receiver"
                                        initialValues={getInitialValues()}
                                        disabled={isLoading}
                                        setFieldValue={setFieldValue}
                                        highlightFor={form.role}
                                        type="orderItems[0].deliveryReceiver"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </div>
    )
})

const LoadingOverlap = ({ show }: LoadingOverlapProps) => {
    if (show) {
        return (
            <div className="position-absolute bg-white opacity-75 w-100 h-100">
                <Spinner className="position-absolute" style={{ top: '25%', left: '48.5%' }} />
            </div>
        )
    }
}

interface LoadingOverlapProps {
    show?: boolean
}
