import React, { useState, useEffect } from 'react'
import { Alert, Col, Row } from 'react-bootstrap'
import { observer } from 'mobx-react-lite'
import { InfoCircleFill } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'

import useOrderCreateForm from '@/common/hooks/useOrderCreateForm'
import { DeliveryPartyFormDto } from '@/common/models/order'
import DefaultButton from '@/components/buttons/DefaultButton'
import { StepActionBottomBar } from '.'
import OrderService from '@/common/api/OrderService'
import Heading from '@/components/typography/Heading'

const GetOrderCreateResultStep = observer(() => {
    const { t } = useTranslation()

    const { orderCreated, resetFormState, updateForm, setFormOrderStatus, goBack, setIsEditing } = useOrderCreateForm()

    const [orderStatus, setOrderStatus] = useState<string>(orderCreated.orderItems[0].status)
    const [isSuccess, setIsSuccess] = useState<boolean>(false)
    const [error, setError] = useState<any>()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const getContacts = (delivaryParty: DeliveryPartyFormDto) => {
        return delivaryParty.phone + 
            (delivaryParty.contactName ? ", "
                + delivaryParty.contactName : '')
    }

    useEffect(() => {
        if (orderStatus === 'READY_FOR_PICKUP') {
            setIsSuccess(true)
            setError(null)
            resetFormState()
        }
    }, [isSuccess, error, orderStatus, resetFormState])

    return (
        <div>
            <div className="d-flex">
                <StepActionBottomBar>
                    <DefaultButton
                        disabled={isLoading}
                        variant="light"
                        onClick={() => {
                            updateForm(orderCreated)
                            setIsEditing(true)
                            goBack()
                        }}
                        bordered
                    >
                        Редактировать
                    </DefaultButton>

                    {orderStatus === 'NEW' && (
                        <DefaultButton
                            isLoading={isLoading}
                            onClick={() => {
                                setIsLoading(true)
                                OrderService.changeStatus(orderCreated.orderItems[0].id, 'CONFIRM_NEW_ORDER')
                                    .then((newStatus) => {
                                        setFormOrderStatus(newStatus)
                                        setOrderStatus(newStatus)
                                    })
                                    .then(() => {
                                        setIsSuccess(true)
                                        setError(null)
                                        resetFormState()
                                    })
                                    .catch((e) => {
                                        if (e.message.includes('ILLEGAL_STATE_TRANSITION_ERROR')) {
                                            return resetFormState()
                                        }
                                        setIsSuccess(false)
                                        setError(e)
                                    })
                                    .finally(() => setIsLoading(false))
                            }}
                            bordered
                        >
                            Потдвердить заказ
                        </DefaultButton>
                    )}
                </StepActionBottomBar>
            </div>
            <div className="my-3">
                {(isSuccess || orderStatus !== 'NEW') && (
                    <Alert className="border-rounded-1 d-flex flex-column" variant="success">
                        <Heading size="sm">Заказ подтверждён</Heading>
                        <div>
                            <span>
                                Заказ переведён в статус
                            </span>
                            <span className="heading-sm px-1">
                                {t(`orderStatus.${orderStatus}`)}
                            </span>
                        </div>
                    </Alert>
                )}
                {error && (
                    <Alert className="border-rounded-1" variant="danger">
                        <Heading size="sm">Произошла ошибка</Heading>
                        <span>{JSON.stringify(error.response?.body)}</span>
                    </Alert>
                )}
            </div>
            <div className="mb-3">
                <div className="d-flex align-items-center text-muted my-2">
                    <InfoCircleFill className="opacity-50" />
                    <span className="mx-2">Вы всегда можете вернуться к заказу в разделе Заказы</span>
                </div>
                <div>
                    <Heading size="sm">Номер заказа</Heading>
                    <div>
                        <span>{orderCreated.orderItems[0].id}</span>
                    </div>
                </div>
                <div>
                    <Heading size="sm">Статус</Heading>
                    <div>
                        <span>{t(`orderStatus.${orderStatus}`)}</span>
                    </div>
                </div>
                {orderCreated.orderItems[0].createDate && (
                    <div>
                        <Heading size="sm">Создано</Heading>
                        <div>
                            <span>{orderCreated.orderItems[0].createDate}</span>
                        </div>
                    </div>
                )}
                <div>
                    <Heading size="sm">Тип доставки</Heading>
                    <div>
                        <span>
                            {t(`deliveryType.${orderCreated.orderItems[0].deliveryType}`)}
                        </span>
                    </div>
                </div>
                <div>
                    <Heading size="sm">Тип посылки</Heading>
                    <div>
                        <span>
                            {t(`parcelType.${orderCreated.orderItems[0].parcelType}`)}
                        </span>
                    </div>
                </div>
            </div>
            <div>
                <Row>
                    <Col>
                        <DeliveryPartyCard
                            title="Отправитель"
                            companyName={orderCreated.deliverySender.company.name}
                            address={orderCreated.deliverySender.address.addressString}
                            contacts={getContacts(orderCreated.deliverySender)}
                        />
                    </Col>
                    <Col>
                        <DeliveryPartyCard
                            title="Получатель"
                            companyName={orderCreated.orderItems[0].deliveryReceiver.company.name}
                            address={orderCreated.orderItems[0].deliveryReceiver.address.addressString}
                            contacts={getContacts(orderCreated.orderItems[0].deliveryReceiver)}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    )
})

const DeliveryPartyCard = ({ title, companyName, address, contacts }: DeliveryPartyCardProps) => {

    return (
        <div className="d-flex flex-column px-3 py-2 border border-rounded-2">
            <div className="mb-2">
                <Heading size="4">{title}</Heading>
            </div>
            <div>
                <Heading size="sm">Название компании</Heading>
                <div>
                    <span>{companyName}</span>
                </div>
            </div>
            <div>
                <Heading size="sm">Адрес</Heading>
                <div>
                    <span>{address}</span>
                </div>
            </div>
            <div>
                <Heading size="sm">Контакты</Heading>
                <div>
                    <span>{contacts}</span>
                </div>
            </div>
        </div>
    )
}

interface DeliveryPartyCardProps {
    title: string,
    companyName: string,
    address: string,
    contacts: string,
}

export default GetOrderCreateResultStep