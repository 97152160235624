import { makeAutoObservable } from "mobx"
import { DeliveryProcessType } from "../constants/deliveryProcess"
import ResourceService from "../api/ResourceService"

class ResourceStore {

    orderStatusesMap: Record<string, string[]> = {}

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
        this.loadFromLocalStorage()
    }

    setStatuses(type: DeliveryProcessType, statuses: string[]) {
        this.orderStatusesMap[type] = statuses
        this.writeToLocalStorage()
    }

    getStatuses(type: DeliveryProcessType): string[] | undefined {
        return this.orderStatusesMap[type]
    }

    getAllStatuses(): string[] {
        return [...new Set(Object.values(this.orderStatusesMap).flat())]
    }

    async fetchResources() {
        ResourceService.getStatuses()
            .then(response => {
                response.items
                    .forEach((i) => this.setStatuses(i.deliveryProcessType, i.statuses))
            })
            .catch(e => console.error('Unable to fetch resources', e))
    }

    writeToLocalStorage() {
        localStorage.setItem('resource-order-statuses-map', JSON.stringify(this.orderStatusesMap))
    }

    loadFromLocalStorage() {
        const data = localStorage.getItem('resource-order-statuses-map')

        if (data) {
            this.orderStatusesMap = JSON.parse(data)
        }
    }
}

const resourceStore = new ResourceStore()

export default resourceStore
