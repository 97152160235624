import { useEffect } from 'react';


import { useNavigate } from 'react-router-dom';
import DefaultButton from '@/components/buttons/DefaultButton';
import useAuth from '@/common/hooks/useAuth';
import { Roles } from '@/common/constants/roles';

const Home = () => {
    const navigate = useNavigate()
    const user = useAuth()
  useEffect(() => {
    user.hasAnyRole([Roles.Manager, Roles.Admin]) && navigate('/orders')
  }, [navigate, user])

    return (
        <div className="d-flex flex-column py-3 h-100">
            {/* Header */}
            <div>
                <p className="heading-2">Главная</p>
            </div>
            <div>
                {user.hasRole(Roles.Client) && (
                    <DefaultButton onClick={() => navigate('/orders-create')}>
                        Создать заказ
                    </DefaultButton>
                )}
            </div>
        </div>
    )
}

export default Home
