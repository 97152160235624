import React from 'react'
import { Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { ReportCellLoad } from '@/common/models/report'

const ReportCellLoadTable = ({ reportData }: ReportCellLoadTableProps) => {
    const { t } = useTranslation()

    return (
        <div>
            <Table bordered striped size="sm">
                <thead>
                    <tr>
                        <th>{t('reportPage.labels.cell-load.date')}</th>
                        <th>{t('reportPage.labels.cell-load.totalInCells')}</th>
                        <th>{t('reportPage.labels.cell-load.from1to4Days')}</th>
                        <th>{t('reportPage.labels.cell-load.from5to9Days')}</th>
                        <th>{t('reportPage.labels.cell-load.from10to19Days')}</th>
                        <th>{t('reportPage.labels.cell-load.from20to29Days')}</th>
                        <th>{t('reportPage.labels.cell-load.from30Days')}</th>
                    </tr>
                </thead>
                <tbody>
                    {reportData?.rows?.map((row, index) => (
                        <tr key={index}>
                            <td>{row.date}</td>
                            <td>{row.totalCount}</td>
                            <td>{row.count1To4Days}</td>
                            <td>{row.count5To9Days}</td>
                            <td>{row.count10To19Days}</td>
                            <td>{row.count20To29Days}</td>
                            <td>{row.count30AndOlder}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

interface ReportCellLoadTableProps {
    reportData: ReportCellLoad,
}

export default ReportCellLoadTable