import React, {FC, useState} from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { searchOptions } from './options'
import {Roles} from "@/common/constants/roles";

const SearchBar: FC<SearchBarProps> = ({
        onSelect,
        onChange,
        onEnter = () => {},
        className,
        role = Roles.Client,
    }) => {
    const { t } = useTranslation()

    const [searchType, setSearchType] = useState('orderItemId')
    const [searchQuery, setSearchQuery] = useState('')

    const handleOptionChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        setSearchType(e.target.value)
        onSelect(e.target.value)
        setSearchQuery("")
        onChange("")
    }

    const getInputType = (searchType: string): string => {
        if(searchType === "orderItemId"
            || searchType === "orderNumber"
            || searchType === "deliveryReceiverBin"
            || searchType === "deliverySenderBin") {
            return "number"
        } else {
            return "text"
        }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const val = e.target.value
        const parsedVal = isNaN(Number(val)) ? val : Number(val)

        if (typeof parsedVal === 'string' || parsedVal >= 0) {
            onChange(e.target.value)
            setSearchQuery(e.target.value)
        }
    }

    return (
        <div className={`${className}`}>
            <div>
                <InputGroup className="d-flex">
                    <Form.Select
                        className="border-rounded-1 flex-shrink-1"
                        onChange={handleOptionChange}
                    >
                        {searchOptions[role]?.map((o: any, index) => (
                            <option key={index} value={o.value}>{t(`options.searchOptions.${o.value}`)}</option>
                        ))}
                    </Form.Select>
                    <Form.Control
                        className="border-rounded-1 w-75"
                        onChange={handleInputChange}
                        value={searchQuery}
                        type={getInputType(searchType)}
                        onKeyUp={(e) => e.key === 'Enter' && onEnter()}
                    />
                </InputGroup>
            </div>
        </div>
    )
}

interface SearchBarProps {
    onSelect: (option: string) => void,
    onChange: (value: string) => void,
    onEnter?: () => void,
    className?: string,
    role?: string,
}

export default SearchBar
