import { FC } from "react"
import { useTranslation } from "react-i18next"
import QRCode from "react-qr-code"
import { Col, Row } from "react-bootstrap"

import { OrderItem } from "@/common/models/order"
import NoteDeliveryParty from "./NoteDeliveryParty"
import { convertToTimeZone } from "@/common/utils/utils"
import { Formats } from "@/common/constants/dateFormat"
import FieldUnderscore from "./FieldUnderscore"
import Field from "./Field"

const OrderNote: FC<OrderNoteProps> = ({ data }) => {
    const { t } = useTranslation()
    const qrValue: string = JSON
        .stringify({ id: data.id, orderId: data.orderId })
        .replace(/"/g, '\\"')

    return (
        <div className="h-100 d-flex flex-column">
            <div className="d-flex justify-content-between">
                <div>
                    <img 
                        className="brand"
                        src="justsendt.png" 
                        alt=""
                    />
                </div>
                <div className="d-flex flex-column text-end header-4">
                    <span>+7 (771) 780-46-86</span>
                    <span>info@justsend.kz</span>
                </div>
            </div>
            <Row className="dparty-body">
                <Col>
                    <NoteDeliveryParty
                        role="sender"
                        data={data.deliverySender}
                    />
                </Col>
                <Col>
                    <NoteDeliveryParty
                        role="receiver"
                        data={data.deliveryReceiver}
                    />
                </Col>
            </Row>
            <Row className="d-flex align-items-end h-100 pb-3">
                <Col>
                    <div className="order-body">
                        <Field
                            header={t('pages.orderNotePrint.orderOwner')}
                            value={data.ownerUser?.username}
                        />
                        <Field
                            header={t('pages.orderNotePrint.orderCreateDate')}
                            value={convertToTimeZone(data.createDate, false, Formats.DATE_DMY_DOT)}
                        />
                        <Field
                            header={t('pages.orderNotePrint.orderItemId')}
                            value={data.id}
                        />
                    </div>
                    <div className="d-flex qr-body">
                        <div>
                            <QRCode
                                className="ms-auto"
                                size={90}
                                value={qrValue}
                                viewBox={`0 0 256 256`}
                            />
                        </div>
                        <div className="w-100 px-4 header-3">
                            <span>{t('pages.orderNotePrint.deliveredCourier')}:</span>
                            <div className="d-flex flex-column">
                                <div className="underscore-fill my-4" />
                                <div className="underscore-fill" />
                            </div>
                        </div>
                    </div>
                </Col>
                <Col className="signature-body">
                    <div className="header-1">
                        <span>{t('pages.orderNotePrint.receivedBy')}</span>
                    </div>
                    <FieldUnderscore header={t('pages.orderNotePrint.fullName')} />
                    <FieldUnderscore header={t('pages.orderNotePrint.workPosition')} />
                    <FieldUnderscore header={t('pages.orderNotePrint.phone')} />
                    <FieldUnderscore header={t('pages.orderNotePrint.dateTime')} />
                    <FieldUnderscore header={t('pages.orderNotePrint.signature')} />
                </Col>
            </Row>
        </div>
    )
}

interface OrderNoteProps {
    data: OrderItem,
}

export default OrderNote